import Parse from 'parse'
import { observable, action, computed, runInAction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Organization } from '../aggregate/Organization'
import { OrganizationEditVM } from '../view-models/OrganizationEditVM'
import { deserialize } from 'serializr'
import { IOrganizationDTO } from '../dtos/IOrganizationDTO'
import { DataStore } from '../../shared/data/DataStore'
import ReactGA from 'react-ga'

export class OrganizationsStore extends DataStore<Organization, IOrganizationDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, Organization, 'organizations', [
      //TODO: We don't use the list of orgs in this store, just the FullRecord, so move the load to getFullRecord()
      'name',
      'adminEmail',
      'isCalendarEnabled',
      'isChatEnabled',
      'isSurveysEnabled',
      'isTasksEnabled',
      'isConsentsEnabled',
      'isGoalsEnabled',
      'isTrainingsEnabled',
      'isPulseCampaignsEnabled',
      'isCustomCollectionsEnabled',
      'isEmailChecked',
      'isPushChecked',
      'isTextChecked',
      'isActionableInsightsEnabled',
      'isContentEnabled',
      'anonymizeSurveys',
      'forceSurveyEmails',
      'forceSurveyTextMessages',
      'hasEmployeeId',
      'hasCMSPicker',
      'hasTrainingPlanFlows',
      'hasJobNumber',
      'hasEnglish',
      'hasGerman',
      'hasPseudo',
      'logoUrl',
      'tableauSiteName',
      'isConsentRequiredForLogin',
      'canImpersonateUsers',
      'tasksAllowPartnerChannel',
      'tasksAllowFeedback',
      'tasksAllowDiscipline',
      'gaTrackingName',
      'allowSurveyTags',
      'autoSaveSurveys',
      'allowSeparateUsernames',
      'defaultFileTypes',
      'additionalFileTypes',
      'tenantLinks',
      'welcomeBanner',
      'hasSurveyStreak',
      'anonymousSurveyResultCount',
      'accessDomain',
      'canArchiveSurveys',
      'archivedAfterDays',
      'mobileAppId',
    ])
  }

  @observable public editVM: OrganizationEditVM = null
  @observable public loaded: boolean = false

  @computed
  public get organizations(): Organization[] {
    return this.records
  }

  @action
  public onListRecordsLoaded() {
    this.loadEditVM()
    this.loaded = true
    this.reportTenantNameToGA()
  }

  private reportTenantNameToGA() {
    ReactGA.set({ dimension1: this.currentOrganization.gaTrackingName })
  }

  protected getBaseQuery() {
    const query = new Parse.Query(this.className)
    query.equalTo('objectId', this.rootStore.appStore.currentOrgId)
    return query
  }

  public loadEditVM(isOpen: boolean = false) {
    if (!this.currentOrganization) return
    this.editVM = new OrganizationEditVM(this.rootStore, this.currentOrganization.clone(), isOpen)
  }

  @computed
  public get currentOrganization(): Organization {
    return this.getOrganization(this.rootStore.appStore.currentOrgId)
  }

  public getOrganization(objectId): Organization {
    return this.organizations.find((e) => e.objectId === objectId)
  }

  @action
  public openDrawer() {
    this.loadEditVM()
    this.editVM.openDrawer()
  }

  @action
  public closeDrawer() {
    this.editVM.closeDrawer()
    this.editVM = null
  }
}
