import { RootStore } from '../../../stores/RootStore'
import { Organization } from '../../aggregate/Organization'
import { observable, computed, action } from 'mobx'
import { OrganizationsSAService } from '../../service/OrganizationsSAService'
import { Label } from '../../aggregate/Label'
import { deserialize } from 'serializr'
import { OrganizationEditLabelRowVM } from './OrganizationEditLabelRowVM'
import { Zone } from '../../aggregate/Zone'
import ThreeDRotationSharp from '@material-ui/icons/ThreeDRotationSharp'
import { OrganizationsService } from '../../..//organizations/service/OrganizationsService'
import { IUserFederationSettings } from '../../../federatedImports/interfaces/IUserFederationSettings'
import { MobileAppsService } from '../../../mobileApps/services/MobileAppsService'
import { IOrganizationTemplateDTO } from '../../../organizationTemplates/dtos/IOrganizationTemplateDTO'
import { IFederatedGroupDTO } from '../../../federatedImports/dtos/IFederatedGroupDTO'

export class OrganizationEditVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, organization?: Organization, labels?: Label) {
    this.organization = organization
    this.cleanOrganization = organization.clone()
    this.rootStore = rootStore
    this.loadOrganizationLabels()
    if (this.organization.zone) this.zoneObjectId = this.organization.zone.objectId
    if (this.organization.additionalFileTypes)
      this.loadAdditionalFileTypes(this.organization.additionalFileTypes)
  }

  @observable public organization: Organization = null
  @observable public cleanOrganization: Organization = null
  @observable public labels: Array<OrganizationEditLabelRowVM> = []
  @observable public isProcessing: boolean = false
  @observable public isEditMode: boolean = true
  @observable public nameError: boolean = false
  @observable public emailError: boolean = false
  @observable public saveTried: boolean = false
  @observable public zoneError: boolean = false
  @observable public zoneObjectId: string = ''
  @observable public tabValue: number = 0
  @observable public analyzeResponse: boolean = false
  @observable public templateName: string = ''
  @observable public searchTemplateTerms: string = null
  @observable public organizationTemplates: IOrganizationTemplateDTO[] = []
  @observable public loadingOrganizationTemplates: boolean = false
  @observable public organizationTemplate: IOrganizationTemplateDTO = null
  @observable public isCreatingTemplate: boolean = false
  @observable public orgTemplateNameIsInvalid: boolean = false
  @observable public isCheckingOrgTemplateName: boolean = false
  @observable public orgNameIsInvalid: boolean = false
  @observable public isCheckingOrgName: boolean = false
  @observable public showTemplateSnackBar: boolean = false
  @observable public templateSnackBarMessage: string = ''
  @observable public isGeneratingToken: boolean = false
  @observable public fileTypesAsString: string = ''
  @observable public isTestingUserFederation: boolean = false
  @observable public userFederationTestStatus: 'success' | 'fail' | 'notrun' = 'notrun'
  @observable public isStartingFederatedUserSync: boolean = false
  @observable public federatedUserSyncStatus: 'success' | 'fail' | 'notrun' = 'notrun'
  @observable public groupOptions: IFederatedGroupDTO[] = []
  public groupByFieldOptions: string[] = ['officeLocation']
  @observable public fetchingGroups: boolean = false
  @observable public loadingMobileSchemes: boolean = false
  @observable public mobileAppSchemes: string[] = []

  @action
  public async loadMobileAppSchemes() {
    this.loadingMobileSchemes = true
    const svc = new MobileAppsService()
    const result = await svc.getMobileAppSchemes()
    if (result.success) {
      this.mobileAppSchemes = result.schemes
    }
    this.loadingMobileSchemes = false
  }

  @action
  public async loadOrganizationTemplates(terms?: string) {
    if (!terms) console.log('onFocus')
    this.loadingOrganizationTemplates = true
    const svc = new OrganizationsSAService()
    const result = await svc.searchOrganizationTemplates(this.searchTemplateTerms)
    this.organizationTemplates = result.organizationTemplates
    this.loadingOrganizationTemplates = false
  }

  @action
  public loadAdditionalFileTypes(fileTypes: string[]) {
    this.fileTypesAsString = fileTypes.join(', ')
  }

  @action
  public async setOrganizationTemplate(val) {
    this.organizationTemplate = val
  }

  @action
  public async loadOrganizationLabels() {
    const orgId = this.organization ? this.organization.objectId : ''
    if (!orgId) return
    const svc = new OrganizationsSAService()
    const labels = await svc.getOrganizationLabels(orgId)
    const formattedLabels = deserialize(Label, labels)
    this.labels = formattedLabels.map(
      (label) => new OrganizationEditLabelRowVM(this.rootStore, label, orgId)
    )
  }

  @computed
  public get customCollectionAPIToken(): string {
    return this.organization.customCollectionAPIToken
  }

  @computed
  public get objectId(): string {
    return this.organization.objectId
  }

  @computed
  public get name(): string {
    return this.organization.name
  }

  @computed
  public get adminEmail(): string {
    return this.organization.adminEmail
  }

  @computed
  public get zone(): Zone {
    return this.organization.zone
  }

  @computed
  public get isNew(): boolean {
    return this.organization.isNew
  }

  @computed
  public get adminPhone(): string {
    return this.organization.adminPhone
  }

  @computed
  public get tableauSiteName(): string {
    return this.organization.tableauSiteName
  }

  @computed
  public get mobileAppId(): string {
    return this.organization.mobileAppId
  }

  @computed
  public get isSurveysEnabled(): boolean {
    return this.organization.isSurveysEnabled
  }

  @computed
  public get isCalendarEnabled(): boolean {
    return this.organization.isCalendarEnabled
  }

  @computed
  public get isChatEnabled(): boolean {
    return this.organization.isChatEnabled
  }

  @computed
  public get isTextChecked(): boolean {
    return this.organization.isTextChecked
  }

  @computed
  public get isPushChecked(): boolean {
    return this.organization.isPushChecked
  }

  @computed
  public get isEmailChecked(): boolean {
    return this.organization.isEmailChecked
  }

  @computed
  public get isTasksEnabled(): boolean {
    return this.organization.isTasksEnabled
  }

  @computed
  public get isConsentsEnabled(): boolean {
    return this.organization.isConsentsEnabled
  }

  @computed
  public get isGoalsEnabled(): boolean {
    return this.organization.isGoalsEnabled
  }

  @computed
  public get isTrainingsEnabled(): boolean {
    return this.organization.isTrainingsEnabled
  }

  @computed
  public get isCustomCollectionsEnabled(): boolean {
    return this.organization.isCustomCollectionsEnabled
  }

  @computed
  public get isPulseCampaignsEnabled(): boolean {
    return this.organization.isPulseCampaignsEnabled
  }

  @computed
  public get isActionableInsightsEnabled(): boolean {
    return this.organization.isActionableInsightsEnabled
  }

  @computed
  public get isContentEnabled(): boolean {
    return this.organization.isContentEnabled
  }

  @computed
  public get anonymizeSurveys(): boolean {
    return this.organization.anonymizeSurveys
  }

  @computed
  public get forceSurveyEmails(): boolean {
    return this.organization.forceSurveyEmails
  }

  @computed
  public get forceSurveyTextMessages(): boolean {
    return this.organization.forceSurveyTextMessages
  }

  @computed
  public get allowSeparateUsernames(): boolean {
    return this.organization.allowSeparateUsernames
  }

  @computed
  public get discludePeersFromAudience(): boolean {
    return this.organization.discludePeersFromAudience
  }

  @computed
  public get disableOldAudience(): boolean {
    return this.organization.disableOldAudience
  }

  @computed
  public get hasEmployeeId(): boolean {
    return this.organization.hasEmployeeId
  }

  @computed
  public get hasCMSPicker(): boolean {
    return this.organization.hasCMSPicker
  }

  @computed
  public get hasTrainingPlanFlows(): boolean {
    return this.organization.hasTrainingPlanFlows
  }

  @computed
  public get hasSurveyStreak(): boolean {
    return this.organization.hasSurveyStreak
  }

  @computed
  public get hasJobNumber(): boolean {
    return this.organization.hasJobNumber
  }

  @computed
  public get hasEnglish(): boolean {
    return this.organization.hasEnglish
  }

  @computed
  public get hasGerman(): boolean {
    return this.organization.hasGerman
  }

  @computed
  public get hasPseudo(): boolean {
    return this.organization.hasPseudo
  }

  @computed
  public get zoneId(): string {
    return this.organization.zone.objectId
  }

  @computed
  public get languages(): Array<string> {
    let languages = []
    if (this.organization.hasEnglish) languages.push('EN')
    if (this.organization.hasGerman) languages.push('DE')
    return languages
  }

  @computed
  public get canImpersonateUsers() {
    return this.organization.canImpersonateUsers
  }

  @computed
  public get allowSurveyTags() {
    return this.organization.allowSurveyTags
  }

  @computed
  public get autoSaveSurveys() {
    return this.organization.autoSaveSurveys
  }

  @computed
  public get allowFeedback(): boolean {
    return this.organization.tasksAllowFeedback
  }

  @computed
  public get allowDiscipline(): boolean {
    return this.organization.tasksAllowDiscipline
  }

  @computed
  public get allowPartnerChannel(): boolean {
    return this.organization.tasksAllowPartnerChannel
  }

  @action
  public copyLinkToClipboard() {
    if (!this.organization.customCollectionAPIToken) return
    var copyText = document.getElementById('copyToken') as HTMLInputElement
    copyText.select()
    document.execCommand('copy')
  }

  @action
  public toggleAllowFeedback() {
    this.organization.toggleAllowFeedback()
  }

  @action
  public toggleAllowDiscipline() {
    this.organization.toggleAllowDiscipline()
  }

  @action
  public toggleAllowPartnerChannel() {
    this.organization.toggleAllowPartnerChannel()
  }

  @action
  public toggleCanImpersonateUsers() {
    this.organization.toggleCanImpersonateUsers()
  }

  @action
  public toggleAllowSurveyTags() {
    this.organization.toggleAllowSurveyTags()
  }

  @action
  public toggleAutoSaveSurveys() {
    this.organization.toggleAutoSaveSurveys()
  }

  private orgNameTimeout
  @action
  public setName(val) {
    this.organization.setName(val)
    this.isCheckingOrgName = true
    if (this.orgNameTimeout) {
      clearTimeout(this.orgNameTimeout)
    }
    this.orgNameTimeout = setTimeout(async () => {
      await this.checkOrgName(val, this.organization ? this.organization.objectId : '')
    }, 225)
  }

  @action
  public setFileTypesAsString(fileTypes: string) {
    this.fileTypesAsString = fileTypes
  }

  @action
  public setAdditionalFileTypes() {
    if (!this.fileTypesAsString) return this.organization.setAdditionalFileTypes([])
    this.organization.setAdditionalFileTypes(
      this.fileTypesAsString.replace('.', '').replace(/\s/g, '').split(',')
    )
  }

  @action
  public setAdminEmail(val) {
    this.organization.setAdminEmail(val)
  }

  @action
  public setAdminPhone(val) {
    this.organization.setAdminPhone(val)
  }

  @action setTableauSiteName(val) {
    const filteredVal = val.replace(/[^a-zA-Z0-9]/g, '')
    this.organization.setTableauSiteName(filteredVal)
  }

  @computed
  public get hasExistingTableauSiteName() {
    const cleanTableauSiteName = this.cleanOrganization.tableauSiteName || ''
    if (!cleanTableauSiteName) {
      return false
    }
    return true
  }

  @action
  public setMobileAppId(val) {
    this.organization.mobileAppId = val
  }

  @action
  public toggleIsTextChecked() {
    this.organization.toggleIsTextChecked()
  }

  @action
  public toggleIsPushChecked() {
    this.organization.toggleIsPushChecked()
  }

  @action
  public toggleIsEmailChecked() {
    this.organization.toggleIsEmailChecked()
  }

  @action
  public toggleIsSurveysEnabled() {
    this.organization.toggleIsSurveysEnabled()
  }

  @action
  public toggleIsPulseCampaignsEnabled() {
    this.organization.toggleIsPulseCampaignsEnabled()
  }

  @action
  public toggleIsActionableInsightsEnabled() {
    this.organization.toggleIsActionableInsightsEnabled()
  }

  @action
  public toggleIsContentEnabled() {
    this.organization.toggleIsContentEnabled()
  }

  @action
  public toggleDiscludePeersFromAudience() {
    this.organization.toggleDiscludePeersFromAudience()
  }

  @action
  public toggleDisableOldAudience() {
    this.organization.toggleDisableOldAudience()
  }

  @action
  public toggleIsChatEnabled() {
    this.organization.toggleIsChatEnabled()
  }

  @action
  public toggleIsCalendarEnabled() {
    this.organization.toggleIsCalendarEnabled()
  }

  @action
  public toggleIsTasksEnabled() {
    this.organization.toggleIsTasksEnabled()
  }

  @action
  public toggleIsGoalsEnabled() {
    this.organization.toggleIsGoalsEnabled()
  }

  @action
  public toggleIsTrainingsEnabled() {
    this.organization.toggleIsTrainingsEnabled()
  }

  @action
  public toggleIsCustomCollectionsEnabled() {
    this.organization.toggleIsCustomCollectionsEnabled()
  }

  @action
  public toggleIsConsentsEnabled() {
    this.organization.toggleIsConsentsEnabled()
  }

  @action
  public disableAnonymizeSurveys() {
    this.organization.disableAnonymizeSurveys()
  }

  @action
  public enableAnonymizeSurveys() {
    this.organization.enableAnonymizeSurveys()
  }

  @action
  public disableForceSurveyEmails() {
    this.organization.disableForceSurveyEmails()
  }

  @action
  public enableForceSurveyEmails() {
    this.organization.enableForceSurveyEmails()
  }

  @action
  public disableForceSurveyTextMessages() {
    this.organization.disableForceSurveyTextMessages()
  }

  @action
  public enableForceSurveyTextMessages() {
    this.organization.enableForceSurveyTextMessages()
  }

  @action enableAllowSeparateUsernames() {
    this.organization.enableAllowSeparateUsernames()
  }

  @action
  public disableAllowSeparateUsernames() {
    this.organization.disableAllowSeparateUsernames()
  }

  @action
  public disableHasEmployeeId() {
    this.organization.disableHasEmployeeId()
  }

  @action
  public enableHasEmployeeId() {
    this.organization.enableHasEmployeeId()
  }

  @action
  public disableHasCMSPicker() {
    this.organization.disableHasCMSPicker()
  }

  @action
  public enableHasCMSPicker() {
    this.organization.enableHasCMSPicker()
  }

  @action
  public disableHasTrainingPlanFlows() {
    this.organization.disableHasTrainingPlanFlows()
  }

  @action
  public enableHasTrainingPlanFlows() {
    this.organization.enableHasTrainingPlanFlows()
  }

  @action
  public disableHasSurveyStreak() {
    this.organization.disableHasSurveyStreak()
  }

  @action
  public enableHasSurveySteak() {
    this.organization.enableHasSurveySteak()
  }

  @action
  public disableHasJobNumber() {
    this.organization.disableHasJobNumber()
  }

  @action
  public enableHasJobNumber() {
    this.organization.enableHasJobNumber()
  }

  @action
  public disableCanArchiveSurveys() {
    this.organization.disableCanArchiveSurveys()
  }

  @action
  public enableCanArchiveSurveys() {
    this.organization.enableCanArchiveSurveys()
  }

  @action
  public toggleEnglish() {
    this.organization.toggleEnglish()
  }

  @action
  public toggleGerman() {
    this.organization.toggleGerman()
  }

  @action
  public togglePseudo() {
    this.organization.togglePseudo()
  }

  @computed
  public get customAuthDomain() {
    return this.organization.federationAdapterSettings?.customAuthDomain
  }

  @action
  public setCustomAuthDomain(val: string) {
    this.organization.federationAdapterSettings.customAuthDomain = val
  }

  @computed
  public get tenantId() {
    return this.organization.federationAdapterSettings?.tenantId
  }
  @action
  public setTenantId(val: string) {
    this.organization.federationAdapterSettings.tenantId = val
  }
  @computed
  public get clientId() {
    return this.organization.federationAdapterSettings.clientId
  }

  @action
  public setClientId(val: string) {
    this.organization.federationAdapterSettings.clientId = val
  }

  @action
  public setAdapter(val: string) {}

  @computed
  public get clientSecret() {
    return this.organization.federationAdapterSettings.clientSecret
  }
  @action
  public setClientSecret(val: string) {
    this.organization.federationAdapterSettings.clientSecret = val
  }

  @computed
  public get userFederationEnabled(): boolean {
    return this.organization.federationAdapterSettings.enabled
  }

  @action
  public toggleUserFederationEnabled() {
    this.organization.federationAdapterSettings.enabled =
      !this.organization.federationAdapterSettings.enabled
  }

  @computed
  public get preferEmailOverUPN(): boolean {
    return this.organization.federationAdapterSettings.preferEmailOverUPN
  }

  @action
  public togglePreferEmailOverUPN() {
    this.organization.federationAdapterSettings.preferEmailOverUPN =
      !this.organization.federationAdapterSettings.preferEmailOverUPN
  }

  @computed
  public get userFederationSettingsValid(): boolean {
    if (this.clientSecret && this.clientId && this.tenantId && this.userFederationEnabled) {
      return true
    }
    return false
  }

  @action
  public async testUserFederationSettings() {
    this.isTestingUserFederation = true
    const federationAdapterSettings: IUserFederationSettings =
      this.organization.federationAdapterSettings.serialize()
    const svc = new OrganizationsSAService()
    try {
      const result = await svc.testFederatedUserSync(federationAdapterSettings)
      if (result.success) this.userFederationTestStatus = 'success'
      else this.userFederationTestStatus = 'fail'
    } catch (e) {
      console.log(e)
      this.userFederationTestStatus = 'fail'
    }

    this.isTestingUserFederation = false
  }

  @computed
  public get federatedUserSyncDisabled(): boolean {
    return this.userFederationTestStatus !== 'success' || this.isStartingFederatedUserSync
  }

  @action
  public async startFederatedUserSync(isFull: boolean = false) {
    this.isStartingFederatedUserSync = true
    try {
      const svc = new OrganizationsSAService()
      const result = await svc.startFederatedUserSync(this.organization.objectId, isFull)
      if (result.success) this.federatedUserSyncStatus = 'success'
      else this.federatedUserSyncStatus = 'fail'
    } catch (e) {
      console.log(e)
      this.federatedUserSyncStatus = 'fail'
    }

    this.isStartingFederatedUserSync = false
  }

  @computed
  public get allowedGroupOptions(): Array<IFederatedGroupDTO> {
    return this.groupOptions.slice()
  }

  @computed
  public get groupNames(): Array<IFederatedGroupDTO> {
    return this.organization.federationAdapterSettings.groupNames.slice()
  }

  @action
  public setAllowedGroups(vals: IFederatedGroupDTO[]) {
    this.organization.federationAdapterSettings.groupNames = vals
  }

  @action
  public setGroupByFields(vals: string[]) {
    this.organization.federationAdapterSettings.groupByFields = vals
    console.log(vals)
  }

  @computed
  public get groupByFields(): Array<string> {
    return this.organization.federationAdapterSettings.groupByFields.slice()
  }

  @action
  public async fetchGroups(terms: string) {
    try {
      this.fetchingGroups = true
      this.groupOptions = []

      const svc = new OrganizationsSAService()
      const startTime = new Date().getTime()
      const result = await svc.getGroupsFromAdapter(this.organization.objectId, terms)
      this.groupOptions = result['groups']
    } catch (e) {
      console.log(e)
    } finally {
      this.fetchingGroups = false
    }
  }

  @action
  public setOIDCProvider(val: string) {
    this.organization.oidcProvider = val
  }

  @action
  public setOIDCConnectUrl(val: string) {
    this.organization.oidcConnectUrl = val
  }

  @action
  public setOIDCClientId(val: string) {
    this.organization.oidcClientId = val
  }

  @action
  public setOICDPromptText(val: string) {
    this.organization.oidcPromptText = val
  }

  @action
  public setAccessDomain(val: string) {
    this.organization.accessDomain = val
  }

  @action
  public setZone(val) {
    this.zoneObjectId = val
    this.zoneError = false
    const zone = this.rootStore.organizationsSAStore.zones.find((zone) => zone.objectId === val)
    this.organization.setZone(zone)
  }

  @action
  public setTabValue(num: number) {
    this.tabValue = num
  }

  @action
  public enableCanImportPasswords(): void {
    this.organization.enableCanImportPasswords()
  }

  @action
  public disableCanImportPasswords(): void {
    this.organization.disableCanImportPasswords()
  }

  @computed
  public get canImportPasswords(): boolean {
    return this.organization.canImportPasswords
  }

  @computed get isNameValid(): boolean {
    if (!this.name) return false
    return true
  }

  @computed get isZoneValid(): boolean {
    if (!this.zone) return false
    return true
  }

  @computed get isAdminEmailValid(): boolean {
    const regex = RegExp(
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    )

    if (regex.test(this.adminEmail)) return true
    return false
  }

  @computed
  public get formIsValid(): boolean {
    if (!this.isNameValid) return false
    if (!this.isZoneValid) return false
    if (!this.isAdminEmailValid && !this.isNew) return false
    if (this.OIDCAuthIsRequired && !this.allValid) return false
    if (this.fromEmailAddress && !this.fromEmailValid) return false
    return true
  }

  @action
  public setNameError() {
    this.nameError = true
  }

  @action
  public setZoneError() {
    this.zoneError = true
  }

  @computed
  public get requireOIDCAuth(): boolean {
    return this.organization.requireOIDCAuth
  }

  @computed
  public get enableOIDCAuth(): boolean {
    return this.organization.enableOIDCAuth
  }

  @computed
  public get oidcProvider(): string {
    return this.organization.oidcProvider
  }

  @computed
  public get isOidcProviderValid(): boolean {
    if (this.oidcProvider === '' && this.OIDCAuthIsEnabled) return false
    if (!this.oidcProvider && this.OIDCAuthIsEnabled) return false
    return true
  }

  @computed
  public get oidcConnectUrl(): string {
    return this.organization.oidcConnectUrl
  }

  @computed
  public get isOidcConnectUrlValid(): boolean {
    const isValidHttpUrl = (str: string) => {
      let url
      try {
        url = new URL(str)
      } catch (_) {
        return false
      }
      return url.protocol === 'http:' || url.protocol === 'https:'
    }
    if (!isValidHttpUrl(this.oidcConnectUrl) && this.OIDCAuthIsEnabled) return false
    return true
  }

  @computed
  public get oidcPromptText(): string {
    return this.organization.oidcPromptText
  }

  @computed
  public get accessDomain(): string {
    return this.organization.accessDomain
  }

  @computed
  public get oidcClientId(): string {
    return this.organization.oidcClientId
  }

  @computed
  public get isOidcClientIdValid(): boolean {
    if (!this.oidcClientId && this.OIDCAuthIsEnabled) return false
    if (this.oidcClientId && this.oidcClientId === '') return false
    return true
  }

  @computed
  public get allValid(): boolean {
    if (!this.isOidcClientIdValid) return false
    if (!this.isOidcConnectUrlValid) return false
    if (!this.isOidcProviderValid) return false
    return true
  }

  @action
  public toggleRequireOIDCAuth() {
    if (!this.OIDCAuthIsEnabled) return
    this.organization.requireOIDCAuth = !this.organization.requireOIDCAuth
  }

  @computed
  public get OIDCAuthIsRequired(): boolean {
    if (!this.organization.requireOIDCAuth) return false
    return true
  }

  @action
  public toggleEnableOIDCAuth() {
    this.organization.enableOIDCAuth = !this.organization.enableOIDCAuth
  }

  @computed
  public get OIDCAuthIsEnabled(): boolean {
    if (!this.organization.enableOIDCAuth) return false
    return true
  }

  @action
  public setEmailError() {
    this.emailError = true
  }

  @action
  public async save() {
    this.saveTried = true
    if (!this.allValid) return
    if (!this.isNameValid) this.setNameError()
    if (!this.isZoneValid) this.setZoneError()
    if (!this.isAdminEmailValid && !this.isNew) this.setEmailError()
    if (this.formIsValid) {
      this.isProcessing = true
      const svc = new OrganizationsSAService()
      let returnedOrgId: string = null
      if (!this.organization.objectId) {
        returnedOrgId = await svc.addOrganization(
          this.organization.name,
          this.rootStore.appStore.currentUserId,
          this.zoneObjectId,
          this.organizationTemplate ? this.organizationTemplate.objectId : null
        )

        await this.rootStore.organizationsSAStore.loadOrganizations()
        setTimeout(() => this.rootStore.organizationsSAStore.editOrganization(returnedOrgId), 1000)
      } else {
        this.setAdditionalFileTypes()
        const org = this.organization.serialize()
        const zone = this.rootStore.organizationsSAStore.zones.find(
          (zone) => zone.objectId === this.zoneObjectId
        )
        org.zone = zone
        returnedOrgId = await svc.saveOrganization(org)
        this.labels.forEach(async (label) => {
          if (!label.isDirty) return
          if (!label.organizationId) label.setOrganization(returnedOrgId)
          await svc.saveOrgLabel(label)
        })
      }
      this.cleanOrganization = this.organization.clone()
      this.isProcessing = false
      this.rootStore.organizationsSAStore.toggleDrawer()
      this.rootStore.organizationsSAStore.loadOrganizations()
    }
  }

  @action
  public async delete() {
    const svc = new OrganizationsSAService()
    await svc.deleteOrganization(this.objectId)
    await this.rootStore.organizationsSAStore.loadOrganizations()
    this.rootStore.organizationsSAStore.toggleDrawer()
  }

  @action
  public async generateToken() {
    if (!this.isAdminEmailValid) return
    this.isGeneratingToken = true
    this.organization.setOrganizationTokenId('')
    const svc = new OrganizationsService(this.rootStore)
    const result = await svc.generateTokenForOrganization(this.organization.serialize())

    if (result) {
      this.organization.setOrganizationTokenId(result)
    }
    this.isGeneratingToken = false
  }

  @action
  public toggleAnalyzeResponse() {
    this.organization.toggleAnalyzeResponse()
  }

  @computed
  public get templateIsValid(): boolean {
    return true
  }

  private templateNameTimeout
  @action
  public async setTemplateName(name: string) {
    this.isCheckingOrgTemplateName = true
    this.templateName = name
    if (this.templateNameTimeout) {
      clearTimeout(this.templateNameTimeout)
    }
    this.templateNameTimeout = setTimeout(async () => {
      await this.checkTemplateName(name)
    }, 200)
  }

  @action
  public async checkTemplateName(name: string) {
    const svc = new OrganizationsSAService()
    const result: boolean = await svc.checkOrgTemplateName(name)

    this.orgTemplateNameIsInvalid = result
    this.isCheckingOrgTemplateName = false
  }

  @action async checkOrgName(name: string, organizationId: string) {
    const svc = new OrganizationsSAService()
    if (name !== this.cleanOrganization.name) {
      const result: boolean = await svc.checkOrgName(name, organizationId)
      this.nameError = result
    } else {
      this.nameError = false
    }
    this.isCheckingOrgName = false
  }

  @action
  public async createNewTemplate() {
    this.isCreatingTemplate = true
    const svc = new OrganizationsSAService()
    const result = await svc.createOrganizationTemplate(this.templateName, this.objectId)
    this.isCreatingTemplate = false
    this.templateSnackBarMessage = `Created new template '${this.templateName}'`
    this.templateName = ''
    this.toggleTemplateSnackbar()
  }

  @action
  public toggleTemplateSnackbar() {
    this.showTemplateSnackBar = !this.showTemplateSnackBar
  }

  @computed
  public get anonymousSurveyResultCount() {
    if (this.organization.anonymousSurveyResultCount)
      return this.organization.anonymousSurveyResultCount
    return 14
  }

  @action
  public setAnonymousSurveyResultCount(num: number) {
    this.organization.setAnonymousSurveyResultCount(num)
  }

  @computed
  public get canArchiveSurveys() {
    return this.organization.canArchiveSurveys
  }

  @computed
  public get archivedAfterDays() {
    return this.organization.archivedAfterDays
  }

  @action
  public setArchivedAfterDays(num: number) {
    this.organization.setArchivedAfterDays(num)
  }

  @computed
  public get fromEmailName() {
    return this.organization.fromEmailName
  }

  @action
  public setFromEmailName(val: string) {
    this.organization.setFromEmailName(val)
  }

  @computed
  public get fromEmailAddress() {
    return this.organization.fromEmailAddress
  }

  @action
  public setFromEmailAddress(val: string) {
    this.organization.setFromEmailAddress(val)
  }

  public get fromEmailValid() {
    if (!this.saveTried) return true
    if (this.fromEmailAddress.trim() === '') return true
    const regex = RegExp(
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    )

    return regex.test(this.fromEmailAddress)
  }
}
