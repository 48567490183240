import { action, observable, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Announcement } from '../aggregate/Announcement'
import { UserAnnouncementVM } from './UserAnnouncementVM'

export class AnnouncementsWidgetVM {
  private rootStore: RootStore
  public announcement: Announcement

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @observable public anchorEl: any = null
  @observable public announcementsWidgetOpen: boolean = false
  @observable public cardHeights: Array<any> = []
  @observable public shouldForceUpdate: boolean = false
  @observable public updated: number = 0
  @observable public announcementsRef: any = null

  @computed
  public get rows(): UserAnnouncementVM[] {
    return this.rootStore.userAnnouncementsStore.userAnnouncements
      .map(
        (userAnnouncement, index) =>
          new UserAnnouncementVM(
            this.rootStore,
            userAnnouncement,
            index,
            this,
          )
      )
      .sort((a, b) => (new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime() ? 1 : -1))
  }

  @action
  public forceRefresh() {
    this.forceUpdate()
  }

  @action
  public prefetch(prefetchNum: number = 3) {
    this.rows.forEach((row, idx) => {
      if (idx >= prefetchNum) return
      row.prefetchAttachments()
    })
  }

  @action
  public rowGetter(index) {
    return this.rows[index].height
  }

  @computed
  public get userAnnouncementsCount(): number {
    return this.rootStore.userAnnouncementsStore.userAnnouncements.filter(
      (userAnnouncement) =>
        userAnnouncement.userId === this.rootStore.appStore.currentUserId &&
        !userAnnouncement.isRead
    ).length
  }

  @computed
  public get canCreateAnnouncements(): boolean {
    return this.rootStore.appStore.canCreateAnnouncements
  }

  @computed
  public get isAdmin() {
    const isAdmin = this.rootStore.appStore.isSystemAdmin || this.rootStore.appStore.isOrgAdmin
    return isAdmin
  }

  @action
  public addNewAnnouncement() {
    this.rootStore.announcementsStore.lazyLoadEditVM('new')
  }

  @computed
  public get editPanelShown(): boolean {
    return Boolean(this.rootStore.announcementsStore.editVM)
  }

  @action
  public goBack() {
    if (this.rootStore.announcementsStore.editVM.showParticipantsSelect) {
      this.rootStore.announcementsStore.editVM.toggleParticipantsSelect()
    } else {
      this.rootStore.announcementsStore.lazyLoadEditVM('empty')
    }
  }

  @action
  public setAnchorEl(e) {
    this.anchorEl = e
  }

  @action
  public clearAnchorEl() {
    this.anchorEl = null
    this.toggleAnnouncementsWidgetOpen()
  }

  @action
  public toggleAnnouncementsWidgetOpen() {
    this.announcementsWidgetOpen = !this.announcementsWidgetOpen
    if (!this.announcementsWidgetOpen) this.rootStore.announcementsStore.lazyLoadEditVM('empty')
  }

  @computed
  public get shouldRender(): boolean {
    if (!this.rootStore.userAnnouncementsStore.loaded) return false
    return true
  }

  @action
  public forceUpdate() {
    this.updated = new Date().getTime()
  }

  @computed
  public get loggedInUser() {
    return this.rootStore.appStore.currentUserId
  }

  @action
  public goToAllAnnouncementsList() {
    this.clearAnchorEl()
    this.rootStore.appStore.router.push(`/announcement/list/all`)
  }

  @action
  public goToForLoggedInUserAnnouncementsList() {
    this.clearAnchorEl()
    this.rootStore.appStore.router.push(`/announcement/list/forloggedinuser/${this.loggedInUser}`)
  }
}
