import { action, observable, computed, reaction } from 'mobx'
import moment from 'moment'
import { RootStore } from '../../stores/RootStore'
import { Announcement } from '../aggregate/Announcement'
import { UserAnnouncement } from '../aggregate/UserAnnouncement'
import { AnnouncementsService } from '../service/AnnouncementsService'
import { AnnouncementAttachmentVM } from './AnnouncementAttachmentVM'
import { AnnouncementsWidgetVM } from './AnnouncementsWidgetVM'
import { AvatarUserMinimum } from '../../shared/Avatar'
import { OldAttachmentVM } from '../../attachments/view-models/OldAttachmentVM'
import { AttachmentVM } from '../../attachments/view-models/AttachmentVM'
import { isNumeric } from '../../shared/isNumeric'
import clip from 'text-clipper'

export class UserAnnouncementVM {
  private rootStore: RootStore
  private userAnnouncement: UserAnnouncement
  private svc: AnnouncementsService
  private widgetVM: AnnouncementsWidgetVM
  public index: number

  constructor(
    rootStore: RootStore,
    userAnnouncement: UserAnnouncement,
    index: number,
    widgetVM: AnnouncementsWidgetVM,
  ) {
    this.rootStore = rootStore
    this.userAnnouncement = userAnnouncement
    this.index = index
    this.widgetVM = widgetVM
    this.svc = new AnnouncementsService()
    this.isCollapsed = this.getInitialExpandedState()

    reaction(
      () => this.isMarkingAsRead,
      () => {
        this.setIsMarkingAsRead(false)
      },
      { delay: 2000 }
    )
  }

  @observable public editIconShown: boolean = false
  @observable public isLoaded: boolean = false
  @observable public isMarkingAsRead: boolean = false
  @observable public isCollapsed: boolean = false
  @observable public updated: number = 0
  @observable public updatingLikeStatus: boolean = false

  @action
  public setHeight(height) {
    const heightObject = {
      objectId: this.objectId,
      height: height,
    }
    const foundHeight = this.widgetVM.cardHeights.find((e) => e.objectId === this.objectId)
    if (foundHeight) foundHeight.height = height
    else this.widgetVM.cardHeights.push(heightObject)
    this.widgetVM.forceUpdate()
    this.setIsLoaded()
  }

  @action setIsLoaded() {
    this.isLoaded = true
  }

  @action setIsMarkingAsRead(isMarking: boolean) {
    this.isMarkingAsRead = isMarking
  }

  @computed
  public get height(): number {
    const foundHeight = this.widgetVM.cardHeights.find((e) => e.objectId === this.objectId)
    if (foundHeight) return foundHeight.height
    return 0
  }

  @computed
  public get announcement(): Announcement {
    return this.rootStore.announcementsStore.getAnnouncement(this.userAnnouncement.announcementId)
  }

  @computed
  public get avatarUser(): AvatarUserMinimum {
    let foundUser = undefined
    if (this.userAnnouncement.ownerUserId) {
      foundUser = this.rootStore.audienceMembersStore.getUser(this.userAnnouncement.ownerUserId)
    } else {
      foundUser = this.rootStore.audienceMembersStore.getUser(this.userAnnouncement.createdByUserId)
    }
    if (foundUser)
      return { name: foundUser.name, iconURL: foundUser.iconURL, objectId: foundUser.objectId }
    return { name: this.username, iconURL: '', objectId: this.userId }
  }

  @computed
  public get title(): string {
    return this.userAnnouncement.title
  }

  @computed
  public get body(): string {
    return this.userAnnouncement.body
  }

  @computed
  public get likes(): number {
    if (!this.announcement) return this.userAnnouncement.likes
    return this.announcement.likes
  }

  @computed
  public get views(): number {
    if (!this.announcement) return this.userAnnouncement.views
    return this.announcement.views
  }

  @computed
  public get displayDate(): string {
    return moment(this.userAnnouncement.createdAt).fromNow()
  }

  @computed
  public get createdAt() {
    return moment(this.userAnnouncement.createdAt).toDate()
  }

  @computed
  public get isRead(): boolean {
    return this.userAnnouncement.isRead
  }

  @computed
  public get liked(): boolean {
    return this.userAnnouncement.liked
  }

  @action
  public toggleLiked() {
    return (this.userAnnouncement.liked = !this.userAnnouncement.liked)
  }

  @computed
  public get editable(): boolean {
    if (this.userAnnouncement.createdByUserId === this.rootStore.appStore.currentUserId) return true
    return false
  }

  @computed
  public get canViewAnalytics(): boolean {
    if (
      this.userAnnouncement.createdByUserId === this.rootStore.appStore.currentUserId ||
      this.rootStore.appStore.isSystemAdmin ||
      this.rootStore.appStore.isOrgAdmin
    )
      return true
    return false
  }

  @action
  public prefetchAttachments() {
    this.userAnnouncement.attachments.forEach((e, idx) => {
      this.rootStore.cmsItemAttachmentStore.loadAttachment(e)
    })
  }

  @computed
  public get attachments(): AttachmentVM[] {
    return this.userAnnouncement.attachments.map((e, idx) => {
      if (isNumeric(e.objectId) || e.cmsItemId) {
        return this.rootStore.cmsItemAttachmentStore.loadAttachment(e)
      }
      return new OldAttachmentVM(this.rootStore, e, idx)
    })
  }

  @computed
  public get swiperAttachments(): AttachmentVM[] {
    return this.attachments.filter(
      (doc) => doc.isImage || doc.isVideo || !doc.isLoaded
    ) as AttachmentVM[]
  }

  @computed
  public get fileAttachments(): AttachmentVM[] {
    return this.attachments.filter((doc) => !doc.isImage && !doc.isVideo) as AttachmentVM[]
  }

  @computed
  public get objectId(): string {
    return this.userAnnouncement.objectId
  }

  @computed
  public get username(): string {
    if (this.userAnnouncement.fk_ownerUser) {
      return this.userAnnouncement.fk_ownerUser.name
    } else if (this.userAnnouncement.fk_createdByUser) {
      return this.userAnnouncement.fk_createdByUser.name
    }
    return ''
  }

  @computed
  public get userId(): string {
    if (this.userAnnouncement.ownerUserId) {
      return this.userAnnouncement.ownerUserId
    } else if (this.userAnnouncement.createdByUserId) {
      return this.userAnnouncement.createdByUserId
    }
    return ''
  }

  @computed
  public get isArchived(): boolean {
    const user = this.rootStore.audienceMembersStore.getUser(this.userAnnouncement.createdByUserId)
    return user ? user.isArchived : false
  }

  @action
  public editAnnouncement() {
    if (!this.announcement) return null
    this.rootStore.announcementsStore.lazyLoadEditVM(this.announcement.objectId)
  }

  @action
  public markAsRead() {
    if (this.isRead) return
    if (this.isMarkingAsRead) return
    this.setIsMarkingAsRead(true)
    this.svc.markUserAnnouncementAsRead(
      this.userAnnouncement.objectId,
      this.userAnnouncement.organizationId
    )
  }

  @action
  public predictNewLikeTotal(myLikedStatus: boolean) {
    if (myLikedStatus) {
      this.announcement.likes++
    } else {
      this.announcement.likes--
    }
  }

  @action
  public async toggleLikeAnnouncement() {
    if (this.updatingLikeStatus) return
    this.updatingLikeStatus = true
    this.toggleLiked()
    this.predictNewLikeTotal(this.userAnnouncement.liked)
    this.widgetVM.forceUpdate()
    const result = await this.svc.toggleLikeAnnouncement(
      this.userAnnouncement.announcementId,
      this.userAnnouncement.userId
    )
    this.updatingLikeStatus = false
  }

  @action
  public toggleCollapsed() {
    this.isCollapsed = !this.isCollapsed
    if (!this.isCollapsed) return this.rootStore.announcementsStore.setExpandedAnnouncement(this.objectId)
    this.rootStore.announcementsStore.clearExpandedAnnouncement(this.objectId)
  }

  @action
  private getInitialExpandedState() {
    if (this.rootStore.announcementsStore.expandedAnnouncements.includes(this.objectId)) return false
    return this.isCollapsible
  }

  @action forceUpdate() {
    this.updated = new Date().getTime()
    this.widgetVM.forceUpdate()
  }

  @computed
  public get isCollapsible(): boolean {
    return this.shouldTruncate(this.body)
  }

  @computed
  public get truncated(): string {
    return this.truncate(this.body)
  }

  @action
  public shouldTruncate(preClip: string): boolean {
    const tryClip = this.truncate(preClip)
    if (preClip.length > tryClip.length) return true
    return false
  }

  @action
  public truncate(preClip: string): string {
    return clip(preClip, 700, { html: true, maxLines: 5 })
  }

  @action
  public viewAnalytics() {
    this.rootStore.appStore.router.push(`/announcement/analytics/${this.announcement.objectId}`)
  }

  @action
  public isBoolean(val) {
    return val === false || val === true
  }

  @computed
  public get showAnnouncementOwner() {
    if (this.isBoolean(this.userAnnouncement.showAnnouncementOwner))
      return this.userAnnouncement.showAnnouncementOwner
    return true
  }
}
