import React, { useState } from 'react'
import { observer } from 'mobx-react'
import clsx from 'clsx'
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Slider,
  TextField,
  Typography,
} from '@material-ui/core'
import Rating from '@material-ui/lab/Rating'
import StarIcon from '@material-ui/icons/Star'
import StarOutlineIcon from '@material-ui/icons/StarOutline'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import OptionRow from './PulseQuestionOptionButton'
import { makeStyles } from '@material-ui/core/styles'
import isMobile from '../../../../utils/isMobile'
import StepIndicators from '../../../shared/StepIndicators'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import PulseQuestionOrderTile from './PulseQuestionOrderTile'
import { PulseQuestionVM } from '../../view-models/questions/PulseQuestionVM'
import { MediaItemVM } from '../../../user-surveys/view-models/MediaItemVM'
import { CMSMediaItemVM } from '../../../user-surveys/view-models/CMSMediaItemVM'
import { OldMediaItemVM } from '../../../user-surveys/view-models/OldMediaItemVM'
import PulseQuestionCMSMedia from './PulseQuestionCMSMedia'
import PulseQuestionOldMedia from './PulseQuestionOldMedia'
import './PulseQuestionRow.scss'

interface Props {
  question: PulseQuestionVM
}

const PulseQuestionRow: React.FC<Props> = ({ question }) => {
  const styles = makeStyles((theme) => ({
    vertical: {
      height: isMobile
        ? `${question.options.length * 3 * 12 + 6}px !important`
        : `${question.options.length * 2 * 19 + 10}px !important`,
    },
    markLabelMobile: {
      fontSize: '0.8em',
      minWidth: '100px !important',
      maxHeight: `${15 * 3}px !important`,
    },
    rowContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    media: {
      display: 'flex',
      alignItems: 'center',
      flexShrink: 0,
      flexBasis: 'auto',
    },
    question: {
      flexShrink: 0,
      flexBasis: 'auto',
    },
  }))()

  const renderButtons = () => {
    if (question.type === 'rating') return null
    if (question.type === 'starRating') return null
    if (question.type === 'text') return null
    if (question.type === 'number') return null
    if (question.type === 'order') return null
    return question.options.map((opt, idx) => <OptionRow vm={opt} key={'opt' + idx} />)
  }

  const createMarksArray = () => {
    const marks: Array<any> = []
    question.options.forEach((e) => {
      marks.push({
        value: e.value,
        label: e.text + (e.subText ? '\r\n' + e.subText : ''),
      })
    })
    return marks
  }

  const renderSlider = () => {
    if (question.type !== 'rating' && question.type !== 'starRating') return null
    const isVertical = (isMobile || question.type === 'rating') && question.type !== 'starRating'
    const marks = createMarksArray()
    return (
      <div
        className={clsx('slider-container', question.media.mediaClassName, {
          'mui-slider-container-vertical': isVertical,
          'mui-slider-container-mobile': isMobile,
        })}
      >
        {question.type === 'rating' ? (
          <Slider
            orientation={isVertical ? 'vertical' : 'horizontal'}
            disabled={false}
            key={'slider' + question.objectId + '_' + question.value}
            className={clsx({
              'has-answer': question.hasAnswer,
              'is-mobile': isMobile,
            })}
            classes={{
              vertical: isVertical ? styles.vertical : null,
              markLabel: isMobile ? styles.markLabelMobile : null,
            }}
            component='div'
            min={1}
            defaultValue={question.value}
            valueLabelDisplay='off'
            max={question.options.length}
            marks={marks}
            onChangeCommitted={(e, val) => {
              question.setValue(val)
            }}
          />
        ) : (
          <Grid container direction='column' justifyContent='center' alignItems='flex-start'>
            <div className={'flex-row'}>
              {question.value !== undefined ? (
                <Box className={'star-text'}>{question.ratingText}</Box>
              ) : undefined}
              <Rating
                name={'rating' + question.objectId + '_' + question.value}
                key={'rating' + question.objectId + '_' + question.value}
                className={clsx({
                  'has-answer': question.hasAnswer,
                  'is-mobile': isMobile,
                })}
                disabled={false}
                onChange={(e, val) => {
                  question.setValue(val)
                }}
                value={question.value}
                onChangeActive={(event, newHover) => {
                  question.setHover(newHover)
                }}
                defaultValue={question.value}
                precision={1}
                max={question.options ? question.options.length : 0}
                icon={<StarIcon className='star-icon'></StarIcon>}
                emptyIcon={<StarOutlineIcon className='star-icon'></StarOutlineIcon>}
              />
            </div>
          </Grid>
        )}
      </div>
    )
  }

  const renderTextBox = () => {
    if (question.type !== 'text') return
    const option = question.options[0]
    return (
      <TextField
        id='outlined-name'
        className={'sln-question' + '-option' + option.rank}
        disabled={false}
        value={option.text}
        onChange={(e) => option.setText(e.target.value)}
        margin='none'
        multiline
        fullWidth
        minRows={2}
        variant='outlined'
        onFocus={(e) => e.target.select()}
        placeholder={'Answer here'}
      />
    )
  }

  const options = question.options.map((value, index) => (
    <div id={`ordered-option-idx-${index}`} className='ordered-option-row'>
      <PulseQuestionOrderTile
        value={value}
        index={index}
        disabled={question.isForResponseDisplay}
      />
    </div>
  ))

  const SortableItem: any = SortableElement(({ value }) => (
    <div style={{ zIndex: 99999999 }} className='question-draggable-item'>
      <div className='order-option-title'>{value}</div>
    </div>
  ))

  const SortableList: any = SortableContainer(({ items }) => {
    return (
      <div className='ordered-list-container'>
        {items.map((value, index) => (
          <SortableItem disabled={false} key={`item-${index}`} index={index} value={value} />
        ))}
      </div>
    )
  })

  const renderOrderOptions = () => {
    if (question.type !== 'order') return null
    return <SortableList distance={3} items={options} />
  }

  const renderNumberText = () => {
    if (!question.isDirty) return null
    if (question.validatedNumberResponse) return null
    return (
      <p className='number-text'>
        Min: {question.minimumValueAllowed || 0}, Max: {question.maximumValueAllowed || 0}, Step:{' '}
        {question.numberStep || 0}
      </p>
    )
  }

  const renderNumberInput = () => {
    if (question.type !== 'number') return null
    const option = question.options[0]
    const renderSteps = () => {
      return (
        <StepIndicators
          onIncreaseStep={() => option.increaseResponse()}
          onDecreaseStep={() => option.decreaseResponse()}
        />
      )
    }
    return (
      <>
        <TextField
          id='outlined-name'
          type='number'
          className={'number-field'}
          disabled={false}
          value={option.responseNumber}
          onChange={(e) => option.setNumber(e.target.value)}
          margin='none'
          inputProps={{ step: String(option.numberStep) }}
          variant='outlined'
          onFocus={(e) => e.target.select()}
          placeholder={'Answer here'}
          InputProps={{
            endAdornment: renderSteps(),
          }}
        />
        {renderNumberText()}
      </>
    )
  }

  const renderLightBox = () => {
    if (!question.showLightBox) return null
    const lightBoxStyles = { overlay: { zIndex: 10000 } }
    return (
      <Lightbox
        mainSrc={question.media.path}
        reactModalStyle={lightBoxStyles}
        onCloseRequest={() => question.toggleLightBox()}
      ></Lightbox>
    )
  }

  const [isMediaLoaded, setIsMediaLoaded] = useState(false)
  const [errorLoadingMsg, setErrorLoadingMsg] = useState('')

  const renderMediaItem = (item: MediaItemVM) => {
    if (!question.media.isLoaded) return null
    if (!isMediaLoaded) return null
    if (item.isCMSItem)
      return <PulseQuestionCMSMedia question={question} vm={item as CMSMediaItemVM} />
    return <PulseQuestionOldMedia question={question} vm={item as OldMediaItemVM} />
  }

  const renderMediaPlaceholder = (item: MediaItemVM) => {
    if (isMediaLoaded) return null
    return (
      <>
        <Box className='media-placeholder'>
          <PlayArrowIcon className='media-placeholder-icon' />
        </Box>
        <Typography className='media-error-msg'>{errorLoadingMsg}</Typography>
        <Button variant='contained' color='primary' onClick={() => handleLoadMedia(item)}>
          Load Media
        </Button>
      </>
    )
  }

  const handleLoadMedia = async (item: MediaItemVM) => {
    if (item.isCMSItem) {
      let mediaItem = await (question.media as CMSMediaItemVM).lazyLoadCMSItem()
      if (mediaItem) {
        setIsMediaLoaded(true)
      } else {
        setErrorLoadingMsg('Item not found')
      }
    } else {
      setIsMediaLoaded(true)
    }
  }

  const renderMedia = () => {
    if (!question.hasMedia) return null

    return (
      <Grid container direction='row' justifyContent='center' alignItems='center' spacing={1}>
        <Grid item className='media-wrapper'>
          {renderMediaItem(question.media)}
          {renderMediaPlaceholder(question.media)}
        </Grid>
      </Grid>
    )
  }

  return (
    <div id='PulseQuestionRow' className={question.blurred ? 'blurred' : ''}>
      <Card elevation={0}>
        <CardContent>
          <Grid
            className={styles.rowContainer}
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='stretch'
            spacing={1}
          >
            <Grid item className={styles.media}>
              {renderMedia()}
            </Grid>
            <Grid item lg md sm xs className={styles.question}>
              {renderButtons()}
              {renderSlider()}
              {renderTextBox()}
              {renderNumberInput()}
              {renderLightBox()}
              {renderOrderOptions()}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}

export default observer(PulseQuestionRow)
