import React, { useState, useCallback, useEffect, useMemo } from 'react'
import clsx from 'clsx'
import { inject, observer } from 'mobx-react'
import { Button, CssBaseline, Grid, Menu, MenuItem, Toolbar, Typography } from '@material-ui/core'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import FilterListIcon from '@material-ui/icons/FilterList'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { GrandCategoryTabVM } from '../../view-models/tree/GrandCategoryTabVM'
import { PulseCategoriesManageVM } from '../../view-models/PulseCategoriesManageVM'
import { GrandParentCategoryRowVM } from '../../view-models/tree/GrandParentCategoryRowVM'
import { CategoryQuestionsCountLabelType } from '../../type/QuestionCategoryCountType'
import isIE11 from '../../../../utils/isIE11'
import './TopToolbar.scss'

interface Props {
  vm: PulseCategoriesManageVM
  countLabelType?: CategoryQuestionsCountLabelType
  isTemplate?: boolean
  questions?: any
  templateId?: string
}

const TopToolbar: React.FC<Props> = ({
  vm,
  countLabelType = 'none',
  isTemplate,
  questions,
  templateId,
}) => {
  const safeQuestions = questions || []
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
  const [nonEmptyCategoryNames, setNonEmptyCategoryNames] = useState<string[]>([])

  if (!vm) return null

  const handleOpenMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setMenuAnchorEl(null)
  }, [])

  // Forces the categories to be selected when a template in the previewer is changed
  useEffect(() => {
    if (isTemplate && templateId) {
      vm.selectAllTabs()
    }
  }, [isTemplate, templateId])

  const categoryCount = useCallback(
    (row: GrandParentCategoryRowVM): number => {
      if (countLabelType == 'survey') return row.currentSurveyParentQuestionCount
      else if (countLabelType == 'campaign') return row.parentQuestionSelectedCount
      else return 0
    },
    [countLabelType]
  )

  const buildTabTitle = useCallback(
    (tab: GrandCategoryTabVM) => {
      if (countLabelType == 'none') return tab.name
      const gpFound = vm.getGrandParentCategory(tab.objectId)
      const count = gpFound ? categoryCount(gpFound) : 0
      if (count === 0) return 'none'
      return `${tab.name}${count > 0 ? ` (${count})` : ''}`
    },
    [countLabelType, vm]
  )

  const renderTemplateCategoryButton = useCallback(
    (tab: GrandCategoryTabVM) => {
      const isNotEmptyCategory = safeQuestions.some((q) => q.fk_grandCategoryId?.name === tab.name)
      if (isNotEmptyCategory) {
        setNonEmptyCategoryNames((prevNames) => {
          // Add the category name to the list only if it's not already there
          if (!prevNames.includes(tab.name)) {
            return [...prevNames, tab.name]
          }
          return prevNames
        })
      }
      // Only render if the category is in the non-empty list
      if (nonEmptyCategoryNames.includes(tab.name)) {
        return (
          <Grid item key={`cat-btn-${tab.index}-${tab.objectId}`}>
            <Button
              className={`tab-btn ${tab.isSelected ? 'tab-btn-selected' : 'tab-btn-unselected'}`}
              value={tab.index}
              onClick={() => vm.toggleTabIndex(tab.index)}
            >
              {tab.isSelected ? (
                <CheckCircleIcon className='check-icon' />
              ) : (
                <RadioButtonUncheckedIcon className='uncheck-icon' />
              )}
              {tab.name}
            </Button>
          </Grid>
        )
      } else {
        return null
      }
    },
    [safeQuestions, nonEmptyCategoryNames, vm]
  )

  const renderCategoryButton = useCallback(
    (tab: GrandCategoryTabVM) => {
      const tabTitle = buildTabTitle(tab)
      if (vm.isBuild && tabTitle === 'none') {
        return null
      }
      if (tab.isSelected) {
        return (
          <Grid item key={`cat-btn-${tab.index}-${tab.objectId}-selected`}>
            <Button
              className='tab-btn tab-btn-selected'
              key={'wd' + tab.index}
              value={tab.index}
              onClick={() => vm.toggleTabIndex(tab.index)}
            >
              <CheckCircleIcon className='check-icon' />
              {tabTitle}
            </Button>
          </Grid>
        )
      } else {
        return (
          <Grid item key={`cat-btn-${tab.index}-${tab.objectId}-unselected`}>
            <Button
              className='tab-btn tab-btn-unselected'
              key={'wd' + tab.index}
              value={tab.index}
              onClick={() => vm.toggleTabIndex(tab.index)}
            >
              <RadioButtonUncheckedIcon className='uncheck-icon' />
              {tab.name}
            </Button>
          </Grid>
        )
      }
    },
    [buildTabTitle, vm]
  )

  const tabs = useMemo(() => {
    if (isTemplate) {
      const templateTabs =
        vm.grandCategoryTabs?.map(renderTemplateCategoryButton).filter((tab) => tab !== null) || []
      return templateTabs
    }
    return vm.grandCategoryTabs?.map(renderCategoryButton).filter((tab) => tab !== null) || []
  }, [vm.grandCategoryTabs, isTemplate, renderTemplateCategoryButton])

  const renderGrandCategoryTabs = useMemo(() => {
    if (!tabs.length) return <Typography>No Categories Available</Typography>
    const midIndex = Math.ceil(tabs.length / 2)
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container direction='column'>
            {tabs.slice(0, midIndex)}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container direction='column'>
            {tabs.slice(midIndex)}
          </Grid>
        </Grid>
      </Grid>
    )
  }, [tabs])

  const renderFocusHeaderText = useCallback(() => {
    return (
      <div className='dashboard-text-wrapper'>
        <Typography className='dashboard-title'>Identify the Focus</Typography>
        <Typography className='dashboard-subtitle'>
          Pick from our list of expert-designed, research-backed questions to gather insights and
          align with your campaign goals.
        </Typography>
      </div>
    )
  }, [])

  const renderCategoryFilterButton = useCallback(() => {
    const spin = menuAnchorEl ? 'spin' : ''
    const buttonLabel =
      isTemplate || isTemplate === undefined ? 'Categories' : vm.categoryFilterBtnLabel
    return (
      <Button
        className='category-filter-btn'
        variant='outlined'
        aria-label='Open Categories'
        endIcon={<KeyboardArrowDownIcon className={`chevron ${spin}`} />}
        onClick={handleOpenMenu}
      >
        <div className='category-filter-btn-wrapper'>
          <FilterListIcon />
          {buttonLabel}
        </div>
      </Button>
    )
  }, [handleOpenMenu, isTemplate, menuAnchorEl, vm.categoryFilterBtnLabel])

  const renderCategoryFilterMenu = useCallback(() => {
    const disabled = vm.shouldShowAllCategoriesButtonBeDisabled ? 'disabled' : ''
    const titleText =
      isTemplate || isTemplate === undefined ? '' : vm.numberOfCategoriesSelectedText
    return (
      <Menu
        id='CategoryFilterMenu'
        className='category-filter-menu'
        elevation={2}
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={handleCloseMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: isTemplate ? 'left' : 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: isTemplate ? 'left' : 'right' }}
      >
        <div className='menu-header'>
          <Typography className='menu-title'>{titleText}</Typography>
          <Button
            variant='text'
            aria-label='Show All Categories'
            className={`show-all ${disabled}`}
            disabled={vm.shouldShowAllCategoriesButtonBeDisabled}
            onClick={() => vm.selectAllTabs()}
          >
            Show all
          </Button>
        </div>
        {renderGrandCategoryTabs}
      </Menu>
    )
  }, [handleCloseMenu, isTemplate, menuAnchorEl, renderGrandCategoryTabs, vm])

  return (
    <div id='PulseCampaignTopToolbar'>
      <CssBaseline />
      <div
        className={clsx('dashboard-appbar', {
          'no-transition': isIE11,
        })}
      >
        <Toolbar className='dashboard-toolbar'>
          {renderFocusHeaderText()}
          {renderCategoryFilterButton()}
        </Toolbar>
      </div>
      {renderCategoryFilterMenu()}
    </div>
  )
}

export default inject()(observer(TopToolbar))
