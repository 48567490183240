import React, { useState } from 'react'
import { observer, inject } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import { Button, Popover } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import FilterListIcon from '@material-ui/icons/FilterList'
import { PulseSetsFilterVM } from '../view-models/PulseSetsFilterVM'
import { PulseSetFilterRowVM } from '../view-models/PulseSetFilterRowVM'

interface Props {
  localizationStore?: LocalizationStore
  vm: PulseSetsFilterVM
}

const PulseSetsFilter: React.FC<Props> = ({ vm }) => {
  const [typeAnchorEl, setTypeAnchorEl] = useState(null)
  if (!vm) return

  const renderLabel = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={vm.allChecked}
            onChange={() => vm.toggleAllChecked()}
            name='select-all'
            color='primary'
          />
        }
        label='Select All'
      />
    )
  }

  const renderTypes = () => {
    return vm.rows.map((row: PulseSetFilterRowVM, index) => (
      <FormControlLabel
        key={index}
        control={
          <Checkbox
            checked={row.isChecked}
            onChange={() => row.toggleIsChecked()}
            name={row.name}
            color='primary'
          />
        }
        label={row.name}
      />
    ))
  }

  const renderPopover = () => {
    if (!typeAnchorEl) return
    return (
      <Popover
        open={Boolean(typeAnchorEl)}
        onClose={() => setTypeAnchorEl(null)}
        anchorEl={typeAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className='question-type-popper'
      >
        <div className='question-catalog-type-filter-menu'>
          {renderLabel()}
          {renderTypes()}
        </div>
      </Popover>
    )
  }

  return (
    <>
      <Button
        disabled={!vm.isLoaded}
        className='dashboard-action btn-icon btn-icon-white'
        variant='outlined'
        startIcon={<FilterListIcon />}
        onClick={(e) => setTypeAnchorEl(typeAnchorEl ? null : e.currentTarget)}
      >
        Campaign Template Sets ({vm.selectedCountLabel})
      </Button>
      {renderPopover()}
    </>
  )
}

export default inject('localizationStore')(observer(PulseSetsFilter))
