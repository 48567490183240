import React from 'react'
import MDSpinner from 'react-md-spinner'
import fullLogo from '../../../assets/img/logos/RippleWorx-White.png'
import './RedirectScreen.scss'
import isMobile from '../../../utils/isMobile'
import { RedirectVM } from '../view-models/RedirectVM'
import rootStore from '../../stores/RootStore'

export default class RedirectScreen extends React.Component<any, any> {
  constructor(props) {
    super(props)

    
    const { mobileAppId, organizationId, path, id } = props.params
    const redirectVM = new RedirectVM(rootStore, mobileAppId, organizationId, path, id)
  }

  routerWillLeave(nextLocation) {}

  componentDidMount() {
    if (!this.props.router) return
    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave.bind(this))
  }

  render() {
    return (
      <div id='Redirect'>
        <div className='center-body'>
          <div>
            <img className='logo' alt='RippleWorx Logo' src={fullLogo} />
          </div>
          <MDSpinner size={50} singleColor='#ffffff' />
        </div>
      </div>
    )
  }
}
