import React from 'react'
import { IndexRoute, Route } from 'react-router'
import Layout from '../../layout/Layout'
import { authenticate } from '../../auth/routes/AuthenticatedRoute'
import AnnouncementsRedirectScreen from '../../announcements/views/redirect/AnnouncementsRedirectScreen'

export default function getAnnouncementRoutes() {
  return (
    <Route key={'announcements0'} component={authenticate(Layout)} path='impact'>
      <IndexRoute onEnter={(nextState, replace) => replace('/dashboard/userDashboard')} />
      <Route key={'announcements1'} path='/announcements/redirect' component={AnnouncementsRedirectScreen} />
    </Route>
  )
}
