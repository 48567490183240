import { observable, computed, action } from 'mobx'
import { Media } from '../../surveys/aggregate/Media'
import { MediaItemVM } from './MediaItemVM'
import { ICMSItemDTO } from '../../cms-items/interfaces/ICMSItemDTO'
import { CMSItemsFindService } from '../../cms-items/service/CMSItemsFindService'
import { isNumeric } from '../../shared/isNumeric'

export class CMSMediaItemVM extends MediaItemVM {
  private svc: CMSItemsFindService
  @observable private cmsItem: ICMSItemDTO
  private lazyLoad: boolean = false
  private loadedCmsItemId: number

  constructor(rootStore, media: Media, lazyLoad?: boolean) {
    super(rootStore, media)
    this.lazyLoad = lazyLoad
    this.svc = new CMSItemsFindService(rootStore)
    this.loadedCmsItemId = isNumeric(media.objectId) ? Number(media.objectId) : media.cmsItemId

    const found = rootStore.cmsItemAttachmentStore.getCMSItem(this.loadedCmsItemId)
    if (found) {
      this.cmsItem = found
    } else {
      if (!this.lazyLoad) rootStore.cmsItemAttachmentStore.loadCMSItem(this.loadedCmsItemId)
    }
  }

  @observable public isLoading: boolean = false

  @action
  public async lazyLoadCMSItem() {
    const item = await this.rootStore.cmsItemAttachmentStore.loadCMSItem(this.loadedCmsItemId)
    return item
  }

  @computed
  public get type() {
    if (!this.cmsItem) return null
    return this.cmsItem.Type
  }

  @computed
  public get path(): string {
    return this.url
  }

  @computed
  public get hasMedia(): boolean {
    if (!this.cmsItem) return undefined
    if (this.isMuxVideo) return true
    return Boolean(this.path)
  }

  @computed
  public get isVideo() {
    return this.type === 'video'
  }

  @computed
  public get isImage() {
    return this.type === 'image'
  }

  @computed
  public get isDocument() {
    return !this.isImage && !this.isVideo
  }

  @computed
  public get mediaClassName(): string {
    if (!this.media) return undefined
    if (this.isVideo) return 'video-slider'
    if (this.isImage) return 'image-slider'
    return ''
  }

  @computed
  public get isCMSItem(): boolean {
    if (isNumeric(this.media.objectId)) return true
    return Boolean(this.media.cmsItemId)
  }

  public toDTO() {
    return {
      type: this.type,
      path: this.path,
    }
  }

  @action
  public async loadCMSItem() {
    if (this.isLoading) return
    const svc = new CMSItemsFindService(this.rootStore)

    this.isLoading = true

    if (isNumeric(this.media.objectId))
      this.setCMSItem(await svc.getItem(Number(this.media.objectId)))
    else this.setCMSItem(await svc.getItem(this.media.cmsItemId))

    this.isLoading = false

    return this.cmsItem
  }

  @action
  public setCMSItem(cmsItem: ICMSItemDTO) {
    this.cmsItem = cmsItem
  }

  @computed
  public get cmsItemId(): number {
    return this.media.cmsItemId
  }

  @computed
  public get objectId(): string {
    return this.media.objectId
  }

  @computed
  public get isLoaded() {
    if (!this.cmsItem) return false
    return true
  }

  @computed
  public get fileUrl(): string {
    if (!this.cmsItem || !this.cmsItem.File) return undefined
    return this.cmsItem.File.url
  }

  @computed
  public get thumbnailUrl(): string {
    if (!this.cmsItem) return undefined
    if (this.isMuxVideo) return this.cmsItem.Optimized_Video.playback_id
    if (this.cmsItem.External_Video) return this.cmsItem.External_Video.thumbnailUrl

    let url = ''
    const file = this.cmsItem.File
    if (file && file.formats) {
      url = file.formats.small?.url
      if (!url) url = file.formats.thumbnail?.url
      if (!url) url = file.url
    } else url = file.url

    if (!url) return ''
    return url
  }

  @computed
  public get url(): string {
    if (!this.cmsItem) return undefined
    if (this.cmsItem.External_Video) return this.cmsItem.External_Video.url

    let url = ''
    const file = this.cmsItem.File
    if (file && file.formats) {
      url = file.formats.large?.url
      if (!url) url = file.formats.medium?.url
      if (!url) url = file.formats.small?.url
      if (!url) url = file.formats.thumbnail?.url
      if (!url) url = file.url
    } else url = file.url

    if (!url) return ''
    return url
  }

  @computed
  public get isMuxVideo(): boolean {
    if (!this.cmsItem) return false
    return Boolean(this.cmsItem?.Optimized_Video?.id)
  }

  @computed
  public get hasYouTubeURL(): boolean {
    if (!this.cmsItem) return false
    const url = this.url.toLowerCase()
    return url.includes('youtube') || url.includes('youtu.be')
  }

  @computed
  public get hasVimeoURL(): boolean {
    if (!this.cmsItem) return false
    const url = this.url.toLowerCase()
    return url.includes('vimeo')
  }

  @computed
  public get youTubeId(): string {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
    const match = this.url.match(regExp)
    return match && match[2].length === 11 ? match[2] : null
  }

  @computed
  public get vimeoId(): string {
    let regExp =
      /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
    if (this.url.includes('player.vimeo.com')) {
      regExp =
        /(http|https)?:\/\/(www\.)?player.vimeo.com\/(?:video\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
    }
    const match = this.url.match(regExp)
    return match && match.length ? match[4] : ''
  }

  @computed
  public get embedUrl(): string {
    if (!this.hasYouTubeURL && !this.hasVimeoURL) return ''
    if (this.hasYouTubeURL) return `https://www.youtube.com/embed/${this.youTubeId}`
    else if (this.hasVimeoURL) return `https://player.vimeo.com/video/${this.vimeoId}`
    return ''
  }

  @computed
  public get hasEmbedUrl(): boolean {
    return Boolean(this.embedUrl)
  }
}
