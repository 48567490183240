import { action, computed, observable } from 'mobx'
import { RootStore } from 'src/app/stores/RootStore'

export class AnnouncementsRedirectVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.openAnnouncements()
  }

  @action
  private openAnnouncements(attempts: number = 0) {
    if (attempts > 10) return console.log('Error opening announcements')
    if (!this.rootStore.announcementsStore) {
      return setTimeout(() => this.openAnnouncements(++attempts), 1000)
    }
    if (!this.rootStore.announcementsStore.announcementsWidgetVM) {
      return setTimeout(() => this.openAnnouncements(++attempts), 1000)
    }
    this.rootStore.announcementsStore.announcementsWidgetVM.toggleAnnouncementsWidgetOpen()
    this.rootStore.appStore.router.push('/dashboard/userDashboard')
  }


}
