import React from 'react'
import { inject, observer } from 'mobx-react'
import { PulseCategoriesManageVM } from '../view-models/PulseCategoriesManageVM'
import { GrandParentCategoryRowVM } from '../view-models/GrandParentCategoryRowVM'
import './PulseCategoriesTree.scss'
import GrandParentCategoryRow from './GrandParentCategoryRow'
import PulseCategoryEditModal from './PulseCategoryEditModal'

interface Props {
  vm: PulseCategoriesManageVM
}

const PulseCategoriesTree: React.FC<Props> = ({ vm }) => {
  if (!vm) return null

  const renderGrandCategoryRows = () => {
    const rows = []
    vm.grandParentCategoryRows().forEach((row: GrandParentCategoryRowVM, i) => {
      rows.push(<GrandParentCategoryRow key={i} vm={row} />)
    })
    return rows
  }

  return (
    <div id={'PulseCategoryTree'}>
      <PulseCategoryEditModal />
      {renderGrandCategoryRows()}
    </div>
  )
}

export default inject()(observer(PulseCategoriesTree))
