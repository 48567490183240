import moment from 'moment-timezone'
export class DateUtils {
  public static getBrowserDate(isodatetime: string, timezone: string): Date {
    const deliveryTime = moment.tz(isodatetime, timezone)
    const hour = deliveryTime.hour()
    const minute = deliveryTime.minute()
    const year = deliveryTime.year()
    const day = deliveryTime.date()
    const month = deliveryTime.month()

    const date = moment
      .tz(moment.tz.guess())
      .year(year)
      .month(month)
      .date(day)
      .hour(hour)
      .minute(minute)
      .second(0)
      .millisecond(0)
      .toDate()
    return date
  }

  public static getISOStringFromBrowserInput(timeISO: string, dateISO: string, timezone: string) {
    const hour = moment(timeISO).hour()
    const minute = moment(timeISO).minute()
    let startDate = moment(dateISO)
    const month = startDate.month()
    const date = startDate.date()
    const year = startDate.year()
    // Order is important here.
    const exactDate = moment
      .tz(dateISO, timezone)
      .year(year)
      .month(month)
      .date(date)
      .hour(hour)
      .minute(minute)
      .second(0)
      .millisecond(0)
    const str = exactDate.toISOString()

    return str
  }
}
