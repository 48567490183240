import { action, computed, observable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import Parse from 'parse'
import { QuestionsService } from '../services/QuestionsService'
import { IQuestionsImportResultDTO } from '../dtos/IQuestionsImportResultDTO'
import { ErrorRowVM } from './ErrorRowVM'
import { QuestionImportRowVM } from './QuestionImportRowVM'
import moment from 'moment'
import { IQuestionImportRowDTO } from '../dtos/IQuestionImportRowDTO'
import { Question } from '../../surveys/aggregate/Question'
import { SurveyEditVM } from '../../surveys/view-models/SurveyEditVM'
import env from '../../../env'

export class QuestionsImportVM {
  private rootStore: RootStore
  private svc: QuestionsService
  private subscription: Parse.LiveQuerySubscription
  private query: Parse.Query
  private surveyEditVM: SurveyEditVM
  private capturedRows: any = {}

  constructor(rootStore: RootStore, forSurvey: boolean = false, surveyEditVM: SurveyEditVM = null) {
    this.rootStore = rootStore
    this.forSurvey = forSurvey
    this.surveyEditVM = surveyEditVM
    this.svc = new QuestionsService(this.rootStore)
  }

  @observable public rows: any[] = []
  @observable public file: File = null
  @observable public importProcessing: boolean = false
  @observable public importCompleted: boolean = false
  @observable public acceptingChanges: boolean = false
  @observable public acceptedChangesComplete: boolean = false
  @observable public result: IQuestionsImportResultDTO = null
  @observable public cardHeights: Array<any> = []
  @observable public forSurvey: boolean = false
  @observable public shown: boolean = false

  public handleFileDrop(file) {
    this.clear()
    this.file = file[0]
    return
  }

  @action
  public rowGetter(index: number) {
    return this.questionsRows[index].height + 20
  }

  @computed
  public get hasErrors(): boolean {
    if (!this.result) return false
    if (this.errorRows.length !== 0) return true
    return this.rows.some((row) => {
      if (!row.result) return false
      if (row.result.errorMessage) return true
    })
  }

  @computed
  public get validateEnabled() {
    if (this.importProcessing) return false
    if (this.file) return true
    return false
  }

  @computed
  public get errorRows(): ErrorRowVM[] {
    if (!this.result) return []
    return this.result.errorMessages.map((err, index) => new ErrorRowVM(this.rootStore, err, index))
  }

  @computed
  public get mainQuestionRowsCount(): number {
    let mainQuestions = this.rows.filter((e) => !e['Option'])
    return mainQuestions.length
  }

  @computed
  public get importProgress(): number {
    if (!this.result) return 0
    const val = (this.questionsRows.length / this.mainQuestionRowsCount) * 100
    return val
  }

  @computed
  public get questionsRows(): QuestionImportRowVM[] {
    return this.rows
      .filter((e) => e.result)
      .map((row, index) => new QuestionImportRowVM(this.rootStore, row, this, index))
  }

  @action
  public async downloadTemplate() {
    if (this.forSurvey) {
      const svc = new QuestionsService(this.rootStore)
      const csvData = await svc.exportQuestionsFromSurveyToCsv(
        this.rootStore.appStore.currentOrgId,
        this.surveyEditVM.questionsToDTO()
      )
      const url = window.URL.createObjectURL(new Blob([csvData]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Questions.csv')
      document.body.appendChild(link)
      link.click()
      return
    }
    window.location.href = `${env.var.REACT_APP_API_URL}/exportQuestionsToCsv?orgId=${this.rootStore.appStore.currentOrgId}`
  }

  @action
  public async acceptChanges() {
    if (this.forSurvey) {
      this.addQuestionsToSurvey()
      return
    }
    this.rows = []
    this.capturedRows = {}
    this.importProcessing = true
    this.importCompleted = false
    this.acceptingChanges = true
    const s = this.rootStore.localizationStore.lzStrings.questionImport
    const batchId = moment().format(s.batch_format)
    const fileName = batchId + '.csv'
    const parseFile = await new Parse.File(fileName, this.file, 'text/csv')
    parseFile.setTags({ organizationId: this.rootStore.appStore.currentOrgId })
    await parseFile.save()
    if (this.subscription) this.subscription.unsubscribe()
    let doSave = true
    this.close()
    const result = await this.svc.importQuestionsFromCsv(
      this.rootStore.appStore.currentOrgId,
      parseFile,
      doSave,
      batchId
    )
    result.rows = []
    this.setResult(result)
    if (this.rootStore.questionsStore)
      setTimeout(() => this.rootStore.questionsStore.forceUpdate(), 2000)
  }

  @action
  private addQuestionsToSurvey() {
    this.close()
    this.result.rows.forEach((e) => {
      if (!e.result) return
      if (!e.result.question) return
      const question = Question.create(e.result.question as any)
      this.surveyEditVM.addQuestion(question)
    })
  }

  @computed
  public get processedRowsCount(): number {
    if (!this.result || !this.rows) return 0
    return this.rows.filter((e) => e.result).length
  }

  @computed
  public get allowAcceptChanges(): boolean {
    if (!this.result) return false
    if (!this.validateEnabled) return false
    if (this.hasErrors) return false
    if (this.importProcessing) return false
    if (!this.file) return false
    return true
  }

  @action
  private setResult(result) {
    this.result = result
    if (!this.result) return
    if (!this.result.success) this.importProcessing = false
    if (this.result.success && this.processedRowsCount === this.mainQuestionRowsCount)
      this.importProcessing = false
    if (!this.result.success) this.file = null
  }

  @action
  public async validate() {
    this.importProcessing = true
    this.setResult(null)
    const s = this.rootStore.localizationStore.lzStrings.questionImport
    const batchId = moment().format(s.batch_format)
    const fileName = batchId + '.csv'
    const parseFile = await new Parse.File(fileName, this.file, 'text/csv')
    parseFile.setTags({ organizationId: this.rootStore.appStore.currentOrgId })
    await parseFile.save()
    this.rows = []
    this.capturedRows = {}
    await this.listenToChanges(batchId)
    const result = await this.svc.importQuestionsFromCsv(
      this.rootStore.appStore.currentOrgId,
      parseFile,
      false,
      batchId
    )
    result.rows = []
    this.setResult(result)
  }

  @computed
  public get showDropZone(): boolean {
    if (!this.file) return true
    if (this.questionsRows.length === 0 && !this.importProcessing && this.errorRows.length > 0) {
      return true
    }
    if (this.questionsRows.length) return false
    if (this.importProcessing) return false
    return true
  }

  @computed
  public get showSpinner(): boolean {
    if (this.allowAcceptChanges) return false
    if (this.questionsRows.length > 0) return false
    if (this.importProcessing) return true
    return false
  }

  private async listenToChanges(batchId: string) {
    if (this.subscription) this.subscription.unsubscribe()
    this.query = new Parse.Query('questionImportUpdates')
    this.query.equalTo('batchId', batchId)
    await this.query.find()
    this.subscription = await this.query.subscribe()
    this.subscription.on('create', (e: Parse.Object) => {
      const obj = e.toJSON() as any
      if (obj.updateType === 'row') {
        this.processRowResult(obj.rowData)
      }
    })
  }

  @action
  private processRowResult(row: IQuestionImportRowDTO) {
    if (!this.capturedRows[row.rowIndex]) {
      this.capturedRows[row.rowIndex] = true
      this.rows.push(row)
    }

    if (this.result && this.result.success) {
      this.importProcessing = false
      if (this.acceptingChanges) this.acceptedChangesComplete = true
    }
  }

  @action
  public toggle() {
    this.clear()
    if (this.shown) {
      this.shown = false
      return
    }
    this.shown = true
  }

  @action
  public close() {
    this.shown = false
  }

  @action
  public clear() {
    this.rows = []
    this.result = undefined
    this.file = null
    this.importProcessing = false
    this.importCompleted = false
    this.acceptingChanges = false
    this.acceptedChangesComplete = false
  }
}
