import React from 'react'
import { inject, observer } from 'mobx-react'
import { Grid, Switch, Typography } from '@material-ui/core'
import LocalizationStore from '../../../localization/LocalizationStore'
import PulseCampaignCategoriesTree from '../tree/PulseCampaignCategoriesTree'
import TopToolbar from '../steppers/TopToolbar'
import { PulseCategoriesManageVM } from '../../view-models/PulseCategoriesManageVM'
import './PulseCampaignQuestionsPanel.scss'
import { PulseCampaignEditVM } from '../../view-models/PulseCampaignEditVM'

interface Props {
  vm: PulseCategoriesManageVM
  editVM: PulseCampaignEditVM
  localizationStore?: LocalizationStore
}

const PulseCampaignQuestionsPanel: React.FC<Props> = ({ vm, localizationStore, editVM }) => {
  const { pulseCampaign: lz } = localizationStore.lzStrings
  if (!vm) return null
  return (
    <>
      <div id='PulseCampaignQuestionsPanel'>
        <Grid
          container
          direction='column'
          justifyContent='flex-start'
          alignItems='flex-start'
          className={'pulse-campaign-questions-grid'}
        >
          <Grid item className={'pulse-campaign-questions-grid-header'}>
            <Grid
              container
              direction='row'
              justifyContent='flex-start'
              alignItems='flex-start'
              className={'pulse-campaign-questions-grid-header-grp'}
            >
              <Typography component='span' className='pulse-campaign-questions-grid-header-title'>
                {lz.questions}
              </Typography>
              <TopToolbar vm={vm} countLabelType={'campaign'} />
            </Grid>
          </Grid>
          <Grid item className={'pulse-campaign-questions-grid-questions'}>
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='flex-start'
              className={'pulse-campaign-questions-grid-questions-list'}
            >
              <Grid container id={'PulseCampaignCategoriesTree'}>
                <PulseCampaignCategoriesTree vm={vm} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default inject(
  'pulseCampaignsStore',
  'localizationStore'
)(observer(PulseCampaignQuestionsPanel))
