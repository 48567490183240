import { serializable, deserialize, serialize, object, list, primitive } from 'serializr'
import { observable, action, computed } from 'mobx'
import { Zone } from '../aggregate/Zone'
import { IOrganizationDTO } from '../../organizations/dtos/IOrganizationDTO'
import { UserFederationSettings } from '../../organizations/aggregate/UserFederationSettings'

export class Organization implements IOrganizationDTO {
  public static create() {
    const org = new Organization()
    org.isNew = true
    org.hasEnglish = true
    return org
  }

  @serializable @observable public objectId: string = ''
  @serializable @observable public name: string = ''
  @serializable @observable public adminEmail: string = ''
  @serializable @observable public adminPhone: string = ''
  @serializable @observable public isSurveysEnabled: boolean = false
  @serializable @observable public isPulseCampaignsEnabled: boolean = false
  @serializable @observable public isCalendarEnabled: boolean = false
  @serializable @observable public isChatEnabled: boolean = false
  @serializable @observable public isTextChecked: boolean = false
  @serializable @observable public isEmailChecked: boolean = false
  @serializable @observable public isPushChecked: boolean = false
  @serializable @observable public isConsentsEnabled: boolean = false
  @serializable @observable public isGoalsEnabled: boolean = false
  @serializable @observable public isTrainingsEnabled: boolean = false
  @serializable @observable public isCustomCollectionsEnabled: boolean = false
  @serializable @observable public isConsentRequiredForLogin: boolean = false
  @serializable @observable public isActionableInsightsEnabled: boolean = false
  @serializable @observable public isContentEnabled: boolean = false
  @serializable @observable public anonymizeSurveys: boolean = false
  @serializable @observable public forceSurveyEmails: boolean = false
  @serializable @observable public discludePeersFromAudience: boolean = false
  @serializable @observable public disableOldAudience: boolean = false
  @serializable @observable public forceSurveyTextMessages: boolean = false
  @serializable @observable public isTasksEnabled: boolean = false
  @serializable @observable public hasEmployeeId: boolean = false
  @serializable @observable public hasCMSPicker: boolean = false
  @serializable @observable public hasTrainingPlanFlows: boolean = false
  @serializable @observable public hasJobNumber: boolean = false
  @serializable @observable public hasSurveyStreak: boolean = false
  @serializable @observable public hasEnglish: boolean = false
  @serializable @observable public hasGerman: boolean = false
  @serializable @observable public hasPseudo: boolean = false
  @serializable @observable public canImportPasswords: boolean = false
  @serializable @observable public theme: string = ''
  @serializable @observable public tableauSiteName: string = ''
  @serializable(object(Zone)) @observable public zone: Zone = null
  @serializable @observable public canImpersonateUsers: boolean = false
  @serializable @observable public allowSurveyTags: boolean = false
  @serializable @observable public autoSaveSurveys: boolean = false
  @serializable @observable public allowSeparateUsernames: boolean = false
  @serializable @observable public tasksAllowPartnerChannel: boolean = false
  @serializable @observable public tasksAllowFeedback: boolean = false
  @serializable @observable public tasksAllowDiscipline: boolean = false
  @serializable @observable public requireOIDCAuth: boolean = false
  @serializable @observable public enableOIDCAuth: boolean = false
  @serializable @observable public oidcConnectUrl: string = ''
  @serializable @observable public oidcClientId: string = ''
  @serializable @observable public oidcProvider: string = ''
  @serializable @observable public oidcPromptText: string = ''
  @serializable @observable public analyzeResponse: boolean = true
  @serializable @observable public customCollectionAPIToken: string = ''
  @serializable(list(primitive())) @observable public additionalFileTypes: string[] = []
  @serializable @observable public anonymousSurveyResultCount: number = 10
  @serializable @observable public accessDomain: string = ''
  @serializable @observable public mobileAppId: string = ''
  @serializable @observable public canArchiveSurveys: boolean = true
  @serializable @observable public archivedAfterDays: number = 14
  @serializable @observable public fromEmailName: string = ''
  @serializable @observable public fromEmailAddress: string = ''
  @serializable(object(UserFederationSettings))
  @observable
  public federationAdapterSettings: UserFederationSettings = new UserFederationSettings()

  @observable isNew: boolean = false

  @action
  public toggleAnalyzeResponse() {
    this.analyzeResponse = !this.analyzeResponse
  }

  @action
  public toggleAllowFeedback() {
    this.tasksAllowFeedback = !this.tasksAllowFeedback
  }

  @action
  public toggleAllowDiscipline() {
    this.tasksAllowDiscipline = !this.tasksAllowDiscipline
  }

  @action
  public toggleAllowPartnerChannel() {
    this.tasksAllowPartnerChannel = !this.tasksAllowPartnerChannel
  }

  @action
  public toggleCanImpersonateUsers() {
    this.canImpersonateUsers = !this.canImpersonateUsers
  }

  @action
  public toggleAllowSurveyTags() {
    this.allowSurveyTags = !this.allowSurveyTags
  }

  @action
  public toggleAutoSaveSurveys() {
    this.autoSaveSurveys = !this.autoSaveSurveys
  }

  @action setOrganizationTokenId(tokenId: string) {
    this.customCollectionAPIToken = tokenId
  }

  @action
  public setName(val) {
    this.name = val
  }

  @action setTheme(val) {
    this.theme = val
  }

  @action
  public setAdminEmail(val) {
    this.adminEmail = val
  }

  @action
  public setAdditionalFileTypes(fileTypes: string[]) {
    this.additionalFileTypes = fileTypes
  }

  @action
  public setZone(zoneObj) {
    this.zone = Zone.create(zoneObj)
  }

  @action
  public setAdminPhone(val) {
    this.adminPhone = val
  }

  @action
  public setTableauSiteName(val) {
    this.tableauSiteName = val.replace(' ', '')
  }

  @action
  public toggleIsTextChecked() {
    this.isTextChecked = !this.isTextChecked
  }

  @action
  public toggleIsPushChecked() {
    this.isPushChecked = !this.isPushChecked
  }

  @action
  public toggleIsEmailChecked() {
    this.isEmailChecked = !this.isEmailChecked
  }

  @action
  public toggleIsSurveysEnabled() {
    this.isSurveysEnabled = !this.isSurveysEnabled
  }

  @action
  public toggleIsPulseCampaignsEnabled() {
    this.isPulseCampaignsEnabled = !this.isPulseCampaignsEnabled
  }

  @action
  public toggleIsConsentsEnabled() {
    this.isConsentsEnabled = !this.isConsentsEnabled
  }

  @action
  public toggleIsGoalsEnabled() {
    this.isGoalsEnabled = !this.isGoalsEnabled
  }

  @action
  public toggleIsTrainingsEnabled() {
    this.isTrainingsEnabled = !this.isTrainingsEnabled
  }

  @action
  public toggleIsCustomCollectionsEnabled() {
    this.isCustomCollectionsEnabled = !this.isCustomCollectionsEnabled
  }

  @action
  public toggleIsChatEnabled() {
    this.isChatEnabled = !this.isChatEnabled
  }

  @action
  public toggleIsTasksEnabled() {
    this.isTasksEnabled = !this.isTasksEnabled
  }

  @action
  public toggleIsCalendarEnabled() {
    this.isCalendarEnabled = !this.isCalendarEnabled
  }

  @action
  public toggleIsActionableInsightsEnabled() {
    this.isActionableInsightsEnabled = !this.isActionableInsightsEnabled
  }

  @action
  public toggleIsContentEnabled() {
    this.isContentEnabled = !this.isContentEnabled
  }

  @action
  public toggleDiscludePeersFromAudience() {
    this.discludePeersFromAudience = !this.discludePeersFromAudience
  }

  @action
  public toggleDisableOldAudience() {
    this.disableOldAudience = !this.disableOldAudience
  }

  @action
  public disableAnonymizeSurveys() {
    this.anonymizeSurveys = false
  }

  @action
  public enableAnonymizeSurveys() {
    this.anonymizeSurveys = true
  }

  @action
  public disableForceSurveyEmails() {
    this.forceSurveyEmails = false
  }

  @action
  public enableForceSurveyEmails() {
    this.forceSurveyEmails = true
  }

  @action
  public disableForceSurveyTextMessages() {
    this.forceSurveyTextMessages = false
  }

  @action
  public enableForceSurveyTextMessages() {
    this.forceSurveyTextMessages = true
  }

  @action
  public disableHasEmployeeId() {
    this.hasEmployeeId = false
  }

  @action
  public enableHasEmployeeId() {
    this.hasEmployeeId = true
  }

  @action
  public disableHasCMSPicker() {
    this.hasCMSPicker = false
  }

  @action
  public enableHasCMSPicker() {
    this.hasCMSPicker = true
  }

  @action
  public disableHasTrainingPlanFlows() {
    this.hasTrainingPlanFlows = false
  }

  @action
  public enableHasTrainingPlanFlows() {
    this.hasTrainingPlanFlows = true
  }

  @action
  public disableHasJobNumber() {
    this.hasJobNumber = false
  }

  @action
  public disableHasSurveyStreak() {
    this.hasSurveyStreak = false
  }

  @action
  public enableHasSurveySteak() {
    this.hasSurveyStreak = true
  }

  @action
  public enableAllowSeparateUsernames() {
    this.allowSeparateUsernames = true
  }

  @action
  public disableAllowSeparateUsernames() {
    this.allowSeparateUsernames = false
  }

  @action
  public enableHasJobNumber() {
    this.hasJobNumber = true
  }

  @action
  public disableCanArchiveSurveys() {
    this.canArchiveSurveys = false
  }

  @action
  public enableCanArchiveSurveys() {
    this.canArchiveSurveys = true
  }

  @action
  public toggleEnglish() {
    this.hasEnglish = !this.hasEnglish
  }

  @action
  public toggleGerman() {
    this.hasGerman = !this.hasGerman
  }

  @action
  public togglePseudo() {
    this.hasPseudo = !this.hasPseudo
  }

  @action
  public enableCanImportPasswords() {
    this.canImportPasswords = true
  }

  @action
  public disableCanImportPasswords() {
    this.canImportPasswords = false
  }

  @action
  public setAnonymousSurveyResultCount(num) {
    if (num < 5) return
    this.anonymousSurveyResultCount = num
  }

  @action
  public setArchivedAfterDays(num) {
    if (num < 1) return
    this.archivedAfterDays = num
  }

  @action
  public setFromEmailName(val: string) {
    this.fromEmailName = val
  }

  @action
  public setFromEmailAddress(val: string) {
    this.fromEmailAddress = val
  }

  public clone(): Organization {
    return deserialize(Organization, this.serialize())
  }

  public serialize(): IOrganizationDTO {
    return serialize(this)
  }
}
