import React from 'react'
import { inject, observer } from 'mobx-react'
import { PulseCategoriesManageVM } from '../view-models/PulseCategoriesManageVM'
import { AppBar, Button, CssBaseline, Grid, Menu, Toolbar, Typography } from '@material-ui/core'
import clsx from 'clsx'
import isIE11 from '../../../utils/isIE11'
import './TopToolbar.scss'
import PulseSetsFilter from '../../pulse-sets/views/PulseSetsFilter'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import FilterListIcon from '@material-ui/icons/FilterList'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import { CategoryQuestionsCountLabelType } from 'src/app/pulse-campaigns/type/QuestionCategoryCountType'
import { GrandCategoryTabVM } from '../view-models/GrandCategoryTabVM'

interface Props {
  vm: PulseCategoriesManageVM
  countLabelType?: CategoryQuestionsCountLabelType
}

const PulseCategoriesTabs: React.FC<Props> = ({ vm, countLabelType = 'none' }) => {
  if (!vm) return null

  const renderAddButton = () => {
    return (
      <Button
        variant='contained'
        onClick={() => vm.createNewPulseCategory()}
        className='dashboard-action'
      >
        Create New KMO Category
      </Button>
    )
  }

  const renderSetsFilter = () => {
    return <PulseSetsFilter vm={vm.pulseSetsFilterVM} />
  }

  const renderGrandCategoryTabs = () => {
    const tabs = []
    if (vm.grandCategoryTabs)
      vm.grandCategoryTabs.forEach((tab: GrandCategoryTabVM) => {
        tabs.push(renderCategoryButton(tab))
      })

    const midIndex = Math.ceil(tabs.length / 2)
    const firstColumnTabs = tabs.slice(0, midIndex)
    const secondColumnTabs = tabs.slice(midIndex)

    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container direction='column'>
            {firstColumnTabs}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container direction='column'>
            {secondColumnTabs}
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderCategoryButton = (tab: GrandCategoryTabVM) => {
    const tabTitle = tab.name
    if (tab.isSelected) {
      return (
        <Grid item key={`cat-btn-${tab.index}-${tab.objectId}-selected`}>
          <Button
            className='tab-btn tab-btn-selected'
            key={'wd' + tab.index}
            value={tab.index}
            onClick={() => vm.toggleTabIndex(tab.index)}
          >
            <CheckCircleIcon className='check-icon' />
            {tabTitle}
          </Button>
        </Grid>
      )
    } else {
      return (
        <Grid item key={`cat-btn-${tab.index}-${tab.objectId}-unselected`}>
          <Button
            className='tab-btn tab-btn-unselected'
            key={'wd' + tab.index}
            value={tab.index}
            onClick={() => vm.toggleTabIndex(tab.index)}
          >
            <RadioButtonUncheckedIcon className='uncheck-icon' />
            {tab.name}
          </Button>
        </Grid>
      )
    }
  }

  const renderCategoryFilterButton = () => {
    const spin = vm.categoryFilterMenuShown ? 'spin' : ''
    const buttonLabel = vm.categoryFilterBtnLabel
    return (
      <div className='category-container'>
        <Button
          className='category-filter-btn'
          variant='outlined'
          aria-label='Open Categories'
          endIcon={<KeyboardArrowDownIcon className={`chevron ${spin}`} />}
          onClick={(e) => vm.openCategoryFilterMenu(e.currentTarget)}
        >
          <div className='category-filter-btn-wrapper'>
            <FilterListIcon />
            {buttonLabel}
          </div>
        </Button>
      </div>
    )
  }

  const renderCategoryFilterMenu = () => {
    const disabled = vm.shouldShowAllCategoriesButtonBeDisabled ? 'disabled' : ''
    const titleText = vm.numberOfCategoriesSelectedText
    return (
      <Menu
        id='CategoryFilterMenu'
        className='category-filter-menu'
        elevation={2}
        anchorEl={vm.categoryFilterAnchorEl}
        keepMounted
        open={vm.categoryFilterMenuShown}
        onClose={() => vm.closeCategoryFilterMenu()}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <div className='menu-header'>
          <Typography className='menu-title'>{titleText}</Typography>
          <Button
            variant='text'
            aria-label='Show All Categories'
            className={`show-all ${disabled}`}
            disabled={vm.shouldShowAllCategoriesButtonBeDisabled}
            onClick={() => vm.selectAllTabs()}
          >
            Show all
          </Button>
        </div>
        {renderGrandCategoryTabs()}
      </Menu>
    )
  }

  return (
    <div id='DashboardToolbar'>
      <CssBaseline />
      <AppBar
        className={clsx('dashboard-appbar', {
          'no-transition': isIE11,
        })}
        elevation={0}
      >
        <Toolbar className='dashboard-toolbar'>
          <Typography className='dashboard-title'>Manage Pulse Categories</Typography>
          <div className='dashboard-toolbar-right'>
            <div className='cat-tab-btns'>
              {renderCategoryFilterButton()}
              {renderCategoryFilterMenu()}
              {/* {renderSetsFilter()} */}
              {renderAddButton()}
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  )
}

export default inject()(observer(PulseCategoriesTabs))
