import env from '../../../env'

export class TableauScriptService {
  public static loadTableauScript(callback, remainingRetries = 1) {
    const existingScript = document.getElementById('tableau-js')
    if (!existingScript) {
      const script = document.createElement('script')
      script.src = env.var.REACT_APP_TABLEAU_SERVER_URL + '/javascripts/api/tableau-2.8.1.min.js'
      script.id = 'tableau-js'

      script.defer = false
      script.async = false

      script.onload = () => {
        if (callback && window.tableau) {
          callback()
        }
      }

      script.onerror = () => {
        if (remainingRetries > 0) {
          console.log('Failed to load tableau script, retrying...')
          const existingScript = document.getElementById('tableau-js')
          if (existingScript) document.head.removeChild(existingScript)
          setTimeout(() => {
            this.loadTableauScript(callback, remainingRetries - 1)
          }, 500)
        } else {
          console.error('Error loading tableau script')
        }
      }

      document.head.appendChild(script)
    }
    const tableauExists = window.tableau ? true : false
    if (existingScript && tableauExists && callback) callback()
  }
}
