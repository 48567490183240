import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'semantic-ui-css/semantic.min.css'
import './assets/css/react-grid-layout/styles.css'
import './assets/css/react-resizable/styles.css'
import 'react-sortable-tree/style.css'
import './app/shared/ag-grid/AGGridTooltip.scss'
import 'proxy-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider as MobxProvider } from 'mobx-react'
import { Router, hashHistory, Route, IndexRoute } from 'react-router'
import env from './env'
env.setWindow(window)

import rootStore from './app/stores/RootStore'
import SentryConfig from './app/config/SentryConfig'
import ThemeProvider from '@material-ui/styles/ThemeProvider'
import theme from './app/theme'
import Parse from 'parse'

import ErrorBoundary from './app/app-error/views/ErrorBoundary'
import AppErrorMessage from './app/app-error/views/AppErrorMessage'

import getAuthRoutes from './app/routes/auth'
import getCalendarRoutes from './app/routes/calendar'
// import getDashboardRoutes from './app/dashboards'
import getStyleGuideRoutes from './app/style-guide'
import getUserSurveyRoutes from './app/user-surveys/routes/UserSurveysRoutes'
import getPublicRoutes from './app/public/routes/PublicRoutes'
import getWorksheetsRoutes from './app/worksheets/routes/WorksheetRoutes'
import getSurveyRoutes from './app/routes/surveys'
import getSurveyTemplateRoutes from './app/routes/surveyTemplates'
import getWeightProfileRoutes from './app/weight-profiles'
import getConsentRoutes from './app/consents/routes/ConsentsRoutes'
import getImpactRoutes from './app/routes/impact'
import getCustomCollectionsRoutes from './app/custom-collections/routes/CustomCollectionsRoutes'
import getGoalsRoutes from './app/routes/goals'
import getTrainingItemsRoutes from './app/routes/training-items'
import getTrainingPlansRoutes from './app/routes/training-plans'
import getUserTrainingPlanRoutes from './app/training-plans/user-training-plans/routes/UserTrainingPlanRoutes'
import getSurveyResponsesRoutes from './app/survey-responses/routes/SurveyResponsesRoutes'
import getPADemoRoutes from './app/pademo/routes/PADemoRoutes'
import getUserWorksheetsRoutes from './app/routes/user-worksheets'
import * as serviceWorker from './serviceWorker'
import getDashboardRoutes from './app/dashboardsV2'
import getSurveyResultsRoutes from './app/survey-results/routes/SurveyResultsRoutes'
import getPulseRoutes from './app/routes/pulse'
import getSystemAdminRoutes from './app/routes/system-admin'
import getAppErrorRoutes from './app/app-error/routes/AppErrorRoutes'
import { ParseStorageController } from './ParseStorageController'
import getRedirectRoutes from './app/routes/redirect'
import getSurveyTypeRoutes from './app/routes/survey-types'
import getActionableInsightsRoutes from './app/routes/actionable-insights'
import getAnnouncementRoutes from './app/routes/announcements'
const querystring = require('querystring')

let apiUrl = env.var.REACT_APP_API_URL
Parse.initialize('RippleApp')
Parse.serverURL = apiUrl + '/parse'
const p: any = Parse.CoreManager
p.setStorageController(ParseStorageController)
Parse.CoreManager.set('REQUEST_ATTEMPT_LIMIT', 0)

rootStore.hydrate()
rootStore.appStore.loadData('app initializing')

if (env.var.REACT_APP_USE_SESSION_STORAGE === '1') {
  window.localStorage.removeItem('Parse/RippleApp/currentUser')
  window.localStorage.removeItem('Parse/RippleApp/installationId')
  window.localStorage.removeItem('app')
}

let indexRoute = '/dashboard/userDashboard'

const url = window.location.href
if (url.includes('/svy')) {
  let params = url.split('/svy/')[1]
  let ids = params.split('/')
  const invId = ids[0]
  const orgId = ids[1]
  if (!orgId) window.location.href = `/#/public/take-survey/${invId}`
  else window.location.href = `/#/public/take-survey/${invId}/${orgId}`
  indexRoute = '/auth/spinner'
}
if (url.includes('/error')) {
  window.location.href = '/#/app-error'
  indexRoute = '/app-error'
}

if (url.includes('/auth/passwordReset/expired/')) {
  let token = url.split('/auth/passwordReset/expired/')[1]
  window.location.href = `/#/auth/passwordReset/expired/${token}`
  indexRoute = '/auth/spinner'
}

if (url.includes('/auth/passwordReset')) {
  let token = url.split('/auth/passwordReset/')[1]
  window.location.href = `/#/auth/passwordReset/${token}`
  indexRoute = '/auth/spinner'
}

if (url.includes('/auth/oidcRedirect')) {
  const params = url.split('#')
  window.location.href = `/#/auth/oidclogin?${params[1]}`
}

if (url.includes('/auth/welcome')) {
  let orgId = url.split('/auth/welcome/')[1]
  window.location.href = `/#/auth/welcome/${orgId}`
  indexRoute = '/auth/spinner'
}

const w: any = window
w.rootStore = rootStore

const getRoutes = () => {
  const auth = getAuthRoutes()
  const calendar = getCalendarRoutes()
  const dashboardRoutes = getDashboardRoutes()
  const surveyRoutes = getSurveyRoutes()
  const surveyTemplateRoutes = getSurveyTemplateRoutes()
  const styleGuideRoutes = getStyleGuideRoutes()
  const userSurveyRoutes = getUserSurveyRoutes()
  const surveyResponsesRoutes = getSurveyResponsesRoutes()
  const surveyResultsRoutes = getSurveyResultsRoutes()
  const publicRoutes = getPublicRoutes()
  const paDemoRoutes = getPADemoRoutes()
  const worksheetsRoutes = getWorksheetsRoutes()
  const customCollectionsRoutes = getCustomCollectionsRoutes()
  const weightProfileRoutes = getWeightProfileRoutes()
  const consentRoutes = getConsentRoutes()
  const impactRoutes = getImpactRoutes()
  const pulseRoutes = getPulseRoutes()
  const goalsRoutes = getGoalsRoutes()
  const trainingItemsRoutes = getTrainingItemsRoutes()
  const trainingPlansRoutes = getTrainingPlansRoutes()
  const userTrainingPlanRoutes = getUserTrainingPlanRoutes()
  const userWorksheetsRoutes = getUserWorksheetsRoutes()
  const systemAdminRoutes = getSystemAdminRoutes()
  const appErrorRoutes = getAppErrorRoutes()
  const redirectRoutes = getRedirectRoutes()
  const surveyTypeRoutes = getSurveyTypeRoutes()
  const actionableInsightsRoutes = getActionableInsightsRoutes()
  const announcementRoutes = getAnnouncementRoutes()

  return [
    auth,
    calendar,
    dashboardRoutes,
    surveyRoutes,
    surveyTemplateRoutes,
    styleGuideRoutes,
    userSurveyRoutes,
    publicRoutes,
    worksheetsRoutes,
    weightProfileRoutes,
    consentRoutes,
    impactRoutes,
    customCollectionsRoutes,
    goalsRoutes,
    trainingItemsRoutes,
    trainingPlansRoutes,
    userTrainingPlanRoutes,
    surveyResponsesRoutes,
    surveyResultsRoutes,
    paDemoRoutes,
    userWorksheetsRoutes,
    pulseRoutes,
    systemAdminRoutes,
    appErrorRoutes,
    redirectRoutes,
    surveyTypeRoutes,
    actionableInsightsRoutes,
    announcementRoutes
  ]
}

const stores = {
  rootStore: rootStore,
  appStore: rootStore.appStore,
  surveysStore: rootStore.surveysStore,
  categoriesStore: rootStore.categoriesStore,
  labelsStore: rootStore.labelsStore,
  userStore: rootStore.userStore,
  audienceMembersStore: rootStore.audienceMembersStore,
  usersStore: rootStore.usersStore,
  privilegeSetsStore: rootStore.privilegeSetsStore,
  privilegesStore: rootStore.privilegesStore,
  rolesStore: rootStore.rolesStore,
  groupsStore: rootStore.groupsStore,
  groupsAndRolesStore: rootStore.groupsAndRolesStore,
  organizationsStore: rootStore.organizationsStore,
  localizationStore: rootStore.localizationStore,
  calendarSettingsStore: rootStore.calendarSettingsStore,
  eventTypesStore: rootStore.eventTypesStore,
  locationsStore: rootStore.locationsStore,
  resourcesStore: rootStore.resourcesStore,
  userSurveysStore: rootStore.userSurveysStore,
  consentsStore: rootStore.consentsStore,
  dashboardsStore: rootStore.dashboardsStore,
  notificationsStore: rootStore.notificationsStore,
  surveyTypesStore: rootStore.surveyTypesStore,
  pulseCampaignTypesStore: rootStore.pulseCampaignTypesStore,
  questionsStore: rootStore.questionsStore,
  tasksStore: rootStore.tasksStore,
  taskTemplatesStore: rootStore.taskTemplatesStore,
  worksheetsStore: rootStore.worksheetsStore,
  clientsStore: rootStore.clientsStore,
  userTasksStore: rootStore.userTasksStore,
  taskCommentsStore: rootStore.taskCommentsStore,
  contactsStore: rootStore.contactsStore,
  userEventsStore: rootStore.userEventsStore,
  systemAdminsStore: rootStore.systemAdminsStore,
  weightProfilesStore: rootStore.weightProfilesStore,
  eventsStore: rootStore.eventsStore,
  mediaItemsStore: rootStore.mediaItemsStore,
  organizationsSAStore: rootStore.organizationsSAStore,
  completedSurveyResponsesStore: rootStore.completedSurveyResponsesStore,
  goalsStore: rootStore.goalsStore,
  userGoalsStore: rootStore.userGoalsStore,
  customCollectionsStore: rootStore.customCollectionsStore,
  announcementsStore: rootStore.announcementsStore,
  userAnnouncementsStore: rootStore.userAnnouncementsStore,
  trainingItemsStore: rootStore.trainingItemsStore,
  trainingPlansStore: rootStore.trainingPlansStore,
  userTrainingPlansStore: rootStore.userTrainingPlansStore,
  trainingPlansCatalogStore: rootStore.trainingPlansCatalogStore,
  goalsCatalogStore: rootStore.goalsCatalogStore,
  userDashboardsStore: rootStore.userDashboardsStore,
  organizationUsersStore: rootStore.organizationUsersStore,
  surveyResultsStore: rootStore.surveyResultsStore,
  surveyTagsStore: rootStore.surveyTagsStore,
  pulseQuestionsStore: rootStore.pulseQuestionsStore,
  pulseCategoriesStore: rootStore.pulseCategoriesStore,
  pulseCampaignsStore: rootStore.pulseCampaignsStore,
  pulseSetsStore: rootStore.pulseSetsStore,
}
//TODO: Add an initializedStores array to RootStore and spread it out here

SentryConfig.init()

ReactDOM.render(
  <ErrorBoundary render={(e) => <AppErrorMessage error={e} />}>
    <ThemeProvider theme={theme}>
      <MobxProvider {...stores}>
        <Router history={hashHistory}>
          <Route key={'i0'} path='/'>
            <IndexRoute key={'i1'} onEnter={(nextState, replace) => replace(indexRoute)} />
            {getRoutes()}
          </Route>
        </Router>
      </MobxProvider>
    </ThemeProvider>
  </ErrorBoundary>,
  document.getElementById('app')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onSuccess: () => {
    console.log('SW: registered successfully')
  },
  onUpdate: (reg) => {
    console.log(reg)
    console.log('SW: Update')

    const registrationWaiting = reg.waiting
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' })
      registrationWaiting.addEventListener('statechange', (e) => {
        if (e.target.state === 'activated') {
          if (window.location.href.indexOf('take-survey') >= 0) {
            console.log('skipping update because user is on take survey screen')
            return
          }
          window.location.reload()
        }
      })
    } else {
      console.log('SW: Update not proceeding because registration is not waiting')
    }
  },
})

console.log('v0.0.28')
