import React from 'react'
import {
  Button,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  Tooltip,
  IconButton,
} from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/core'
import { ActionableInsightsVM } from '../view-models/ActionableInsightsVM'
import { observer, useLocalStore } from 'mobx-react'
import './ActionableInsightsHomeScreen.scss'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import {
  FaBolt,
  FaSun,
  FaCloudRain,
  FaInfoCircle,
  FaLongArrowAltDown,
  FaLongArrowAltUp,
} from 'react-icons/fa'
import { RiBardFill } from 'react-icons/ri'

interface Props {}

const ActionableInsightsHomeScreen: React.FC<Props> = () => {
  const localStore = useLocalStore(() => ({
    vm: new ActionableInsightsVM(),
  }))
  const vm = localStore.vm
  if (!vm) return

  if (!vm.shouldRender) {
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderLoadingSpinner = () => {
    if (!vm.spinnerShown) return
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderHeader = () => {
    return (
      <Grid
        container
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        className='header-container'
      >
        <Grid item xs={6}>
          <h1 className='insights-header h1-sm'>Actionable Insights: Climate Campaign</h1>
          <p className='insights-subheader support-text-md'>
            Insight Period: {vm.insightPeriodDates}
          </p>
        </Grid>
        {renderDropdowns()}
      </Grid>
    )
  }

  const renderInsightPeriodDropdown = () => {
    return (
      <div className='left-dropdown-wrapper'>
        <span className='insights-dropdown-label'>Insight Period</span>
        <Button
          className='insights-dropdown label'
          variant='outlined'
          aria-label='View Insight Periods'
          endIcon={<KeyboardArrowDownIcon />}
          onClick={(e) => vm.openInsightPeriodMenu(e.currentTarget)}
        >
          {vm.selectedPeriodName}
        </Button>
        <Menu
          id='InsightPeriodMenu'
          className='insight-dropdown-menu'
          elevation={2}
          anchorEl={vm.insightPeriodAnchorEl}
          keepMounted
          open={vm.insightPeriodMenuShown}
          onClose={() => vm.closeInsightPeriodMenu()}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {vm.periodOptions.map((option) => (
            <MenuItem key={option.value} onClick={() => vm.setSelectedPeriod(option.value)}>
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
    )
  }

  const renderGroupDropdown = () => {
    return (
      <div className='right-dropdown-wrapper'>
        <span className='insights-dropdown-label'>Group</span>
        <Button
          className='insights-dropdown label'
          variant='outlined'
          aria-label='View Groups'
          endIcon={<KeyboardArrowDownIcon />}
          onClick={(e) => vm.openGroupMenu(e.currentTarget)}
        >
          {vm.selectedGroupName}
        </Button>
        <Menu
          id='GroupMenu'
          className='insight-dropdown-menu'
          elevation={2}
          anchorEl={vm.groupAnchorEl}
          keepMounted
          open={vm.groupMenuShown}
          onClose={() => vm.closeGroupMenu()}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {vm.groupOptions.map((option) => (
            <MenuItem key={option.value} onClick={() => vm.setSelectedGroup(option.value)}>
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
    )
  }

  const renderDropdowns = () => {
    if (vm.emptyStateMessageShown) return
    return (
      <>
        <Grid item xs={3}>
          {renderInsightPeriodDropdown()}
        </Grid>
        <Grid item xs={3}>
          {renderGroupDropdown()}
        </Grid>
      </>
    )
  }

  const useStyles = makeStyles(() => ({
    tooltip: {
      fontFamily: 'Helvetica Neue',
      backgroundColor: 'white !important',
      color: '#212227',
      boxShadow: '0px 8px 16px 0px #1B212C1F',
      fontSize: '14px !importnat',
      fontWeight: 400,
      lineHeight: '20px',
      padding: '16px',
      borderRadius: '8px',
    },
    arrow: {
      color: 'white !important',
    },
  }))

  function SentimentTooltip(props) {
    const classes = useStyles()
    return <Tooltip classes={classes} arrow {...props} />
  }

  const renderLeftContainer = () => {
    const sentimentScoreColor = vm.dataAvailable ? '' : 'sentiment-score-disabled'
    const scoreColor = vm.dataAvailable ? '' : 'disabled'
    return (
      <Grid item xs={3} className='left-container'>
        <div className='group-container'>
          <h3 className='group-name h2-sm'>{vm.selectedGroupName}</h3>
          <p className='group-summary-text body-sm'>
            Innovation and collaboration fuel the creation of products that truly impact and
            transform lives.
          </p>
        </div>
        <div className='sentiment-container'>
          <h5 className='sentiment-title h6'>Overall Sentiment</h5>
          <SentimentTooltip
            title='Overall sentiment combines scores from rating questions and open text feedback to give a snapshot of how people feel with "100" being Very Positive and "0" being Very Negative.'
            placement='right'
          >
            <IconButton className='sentiment-tool-tip' disableRipple>
              <FaInfoCircle className='sentiment-tool-tip-icon' />
            </IconButton>
          </SentimentTooltip>
          <div className='sentiment-score-wrapper'>
            <div className='sentiment-score-inner-wrapper'>
              <span className='sentiment-score callout-lg'>64</span>
              <span className='sentiment-score-out-of callout-sm'>/100</span>
            </div>
            <div className='sentiment-direction-wrapper'>
              {vm.dataAvailable && (
                <FaLongArrowAltDown className='sentiment-direction-icon red-arrow' />
              )}
              <span
                className={`sentiment-direction-percentage ${sentimentScoreColor} support-text-lg`}
              >
                {vm.sentimentScore + '%'}
              </span>
              {!vm.dataAvailable && <span className='sentiment-asterisk support-text-lg'>*</span>}
            </div>
          </div>
        </div>
        <div className='response-container'>
          <h6 className='response-title h6'>Response</h6>
          <div className='response-score-wrapper'>
            <span className='response-percentage callout'>50%</span>
            <div className='response-direction-wrapper'>
              {vm.dataAvailable && (
                <FaLongArrowAltUp className='response-direction-icon green-arrow' />
              )}
              <span className={`response-direction-percentage ${scoreColor} support-text-md`}>
                {vm.responseScore + '%'}
              </span>
              {!vm.dataAvailable && <span className='score-asterisk support-text-md'>*</span>}
            </div>
          </div>
        </div>
        <div className='motivation-container'>
          <h6 className='motivation-title h6'>Motivation</h6>
          <div className='motivation-score-wrapper'>
            <div className='motivation-score-inner-wrapper'>
              <span className='motivation-score callout'>72</span>
              <span className='motivation-score-out-of callout-sm'>/100</span>
            </div>
            <div className='motivation-direction-wrapper'>
              {vm.dataAvailable && (
                <FaLongArrowAltDown className='motivation-direction-icon red-arrow' />
              )}
              <span className={`response-direction-percentage ${scoreColor} support-text-md`}>
                {vm.motivationScore + '%'}
              </span>
              {!vm.dataAvailable && <span className='score-asterisk support-text-md'>*</span>}
            </div>
          </div>
          <p className='motivation-perception body-italicized'>Very high</p>
        </div>
        <div className='workload-container'>
          <h6 className='workload-title h6'>Workload</h6>
          <div className='workload-score-wrapper'>
            <div className='workload-score-inner-wrapper'>
              <span className='workload-score callout'>58</span>
              <span className='workload-score-out-of callout-sm'>/100</span>
            </div>
            <div className='workload-direction-wrapper'>
              {vm.dataAvailable && (
                <FaLongArrowAltDown className='workload-direction-icon red-arrow' />
              )}
              <span className={`response-direction-percentage ${scoreColor} support-text-md`}>
                {vm.workloadScore + '%'}
              </span>
              {!vm.dataAvailable && <span className='score-asterisk support-text-md'>*</span>}
            </div>
          </div>
          <p className='workload-perception body-italicized'>Average</p>
        </div>
        {!vm.dataAvailable && (
          <p className='special-note support-text'>
            *Note: Progression data will be available in the next report.
          </p>
        )}
      </Grid>
    )
  }

  const renderCategorySentimentScoreIcon = (score: number) => {
    if (score <= 33) return <FaBolt className='category-sentiment-score-icon red-lightning' />
    if (score > 33 && score < 68)
      return <FaCloudRain className='category-sentiment-score-icon yellow' />
    return <FaSun className='category-sentiment-score-icon green-sun' />
  }

  const renderInsightsContent = () => {
    return (
      <Grid item xs={9}>
        <Grid
          container
          direction='row'
          alignItems='flex-start'
          justifyContent='space-between'
          className='framing-container'
        >
          <Grid item xs={4} style={{ height: '172px' }}>
            <div className='framing-inner-wrapper'>
              <div className='category-text-container'>
                <h4 className='category-name h3-sm'>Personal Framing</h4>
                <p className='category-description support-text'>
                  Building products with innovation drives real change.
                </p>
              </div>
              <div className='category-sentiment-score-container'>
                {renderCategorySentimentScoreIcon(vm.personalFramingScore)}
                <span className='category-sentiment-score callout'>{vm.personalFramingScore}</span>
              </div>
            </div>
          </Grid>
          <Grid item xs={9}>
            <div className='insight-data-item'>
              <RiBardFill className='insight-text-icon' />
              <p className='insight-text body'>
                Many employees express high motivation due to recent skill development opportunities
                and growth initiatives.
              </p>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          direction='row'
          alignItems='flex-start'
          justifyContent='space-between'
          className='framing-container'
        >
          <Grid item xs={4} style={{ height: '172px' }}>
            <div className='framing-inner-wrapper'>
              <div className='category-text-container'>
                <h4 className='category-name h3-sm'>Relational Framing</h4>
                <p className='category-description support-text'>
                  Employees communicate and work together to achieve shared goals.
                </p>
              </div>
              <div className='category-sentiment-score-container'>
                {renderCategorySentimentScoreIcon(vm.relationalFramingScore)}
                <span className='category-sentiment-score callout'>
                  {vm.relationalFramingScore}
                </span>
              </div>
            </div>
          </Grid>
          <Grid item xs={9}>
            <div className='insight-data-item'>
              <RiBardFill className='insight-text-icon' />
              <p className='insight-text body'>
                34% of employees feel their feedback isn't valued, leading to a decline in
                engagement.
              </p>
            </div>
            <div className='insight-data-item'>
              <RiBardFill className='insight-text-icon' />
              <p className='insight-text body'>
                Trust between teams dropped by 12%, with communication issues cited as the main
                cause.
              </p>
            </div>
            <div className='insight-data-item'>
              <RiBardFill className='insight-text-icon' />
              <p className='insight-text body'>
                Recognition programs have driven a 20% rise in positive peer feedback over the past
                6 months.
              </p>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          direction='row'
          alignItems='flex-start'
          justifyContent='space-between'
          className='framing-container'
        >
          <Grid item xs={4} style={{ height: '172px' }}>
            <div className='framing-inner-wrapper'>
              <div className='category-text-container'>
                <h4 className='category-name h3-sm'>Operational Framing</h4>
                <p className='category-description support-text'>
                  Understand their tasks and contribute effectively to the team's overall
                  objectives.
                </p>
              </div>
              <div className='category-sentiment-score-container'>
                {renderCategorySentimentScoreIcon(vm.operationalFramingScore)}
                <span className='category-sentiment-score callout'>
                  {vm.operationalFramingScore}
                </span>
              </div>
            </div>
          </Grid>
          <Grid item xs={9}>
            <div className='insight-data-item'>
              <RiBardFill className='insight-text-icon' />
              <p className='insight-text body'>
                85% of projects were delivered on schedule, contributing to a 10% rise in client
                satisfaction scores.
              </p>
            </div>
            <div className='insight-data-item'>
              <RiBardFill className='insight-text-icon' />
              <p className='insight-text body'>
                Recent process improvements have led to faster decision-making, with teams reporting
                a 30% decrease in time spent on approvals and consultations.
              </p>
            </div>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderContent = () => {
    if (vm.spinnerShown) return
    if (vm.emptyStateMessageShown) return
    return (
      <>
        <Grid
          container
          direction='row'
          alignItems='center'
          justifyContent='flex-end'
          className='insights-legend'
        >
          <Grid item>
            <span className='legend-title'>Sentiment score:</span>
          </Grid>
          <Grid item className='legend-item-wrapper'>
            <FaBolt className='legend-icon red-lightning' />
            <span className='legend-item label'>0-33</span>
          </Grid>
          <Grid item className='legend-item-wrapper' style={{ gap: '4px' }}>
            <FaCloudRain className='legend-icon yellow' />
            <span className='legend-item label'>34-67</span>
          </Grid>
          <Grid item className='legend-item-wrapper' style={{ gap: '2px' }}>
            <FaSun className='legend-icon green-sun' />
            <span className='legend-item label'>68-100</span>
          </Grid>
        </Grid>
        <Grid
          container
          direction='row'
          alignItems='flex-start'
          justifyContent='space-between'
          className='content-container'
        >
          {renderLeftContainer()}
          {renderInsightsContent()}
        </Grid>
      </>
    )
  }

  const renderNoContentMessage = () => {
    if (!vm.emptyStateMessageShown) return
    return (
      <Grid item xs={12}>
        <h2 className='no-summeries-message-text'>No Actionable Insights Generated Yet</h2>
      </Grid>
    )
  }

  return (
    <div id='Insights'>
      {renderLoadingSpinner()}
      <div className='insights-container'>
        {renderHeader()}
        <Grid container direction='row' alignItems='center' justifyContent='space-between'>
          {renderNoContentMessage()}
          {renderContent()}
        </Grid>
      </div>
    </div>
  )
}

export default observer(ActionableInsightsHomeScreen)
