import React from 'react'
import { AnnouncementsRedirectVM } from '../../view-models/redirect/AnnouncementsRedirectVM'
import rootStore from '../../../stores/RootStore'
import CircularProgress from '@material-ui/core/CircularProgress'

export default class AnnouncementsRedirectScreen extends React.Component<any, any> {
  constructor(props) {
    super(props)

    const redirectVM = new AnnouncementsRedirectVM(rootStore)
  }

  routerWillLeave(nextLocation) {}

  componentDidMount() {
    if (!this.props.router) return
    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave.bind(this))
  }

  render() {
    return (
      <CircularProgress color='secondary' style={{ position: 'fixed', top: '50%', left: '50%' }} />
    )
  }
}
