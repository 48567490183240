import React from 'react'
import { inject, observer } from 'mobx-react'
import { Button, Chip, CircularProgress, Grid, IconButton, Typography } from '@material-ui/core'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ChevronDownIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import rootStore from '../../../stores/RootStore'
import LocalizationStore from '../../../localization/LocalizationStore'
import { LabelsStore } from '../../../labels/store/LabelsStore'
import { PulseCategoriesManageVM } from '../../view-models/PulseCategoriesManageVM'
import { PulseQuestionPreviewVM } from '../../view-models/PulseQuestionPreviewVM'
import { PulseCampaignTemplatePreviewerVM } from '../../view-models/PulseCampaignTemplatePreviewerVM'
import TopToolbar from '../steppers/TopToolbar'
import PulseCampaignSurveyQuestionPreviewCard from '../surveys/PulseCampaignSurveyQuestionPreviewCard'
import EmptyState from '../../../shared/empty-state/EmptyState'
import emptyResults from '../../../../assets/img/empty-state/empty-state-results.png'
import './PulseCampaignTemplatePreviewer.scss'

interface Props {
  vm: PulseCampaignTemplatePreviewerVM
  manageVM: PulseCategoriesManageVM
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}

const PulseCampaignTemplatePreviewer: React.FC<Props> = ({
  vm,
  manageVM,
  localizationStore,
  labelsStore,
}) => {
  const { pulseCampaign: lz } = localizationStore.lzStrings

  if (!vm) return null
  if (!manageVM) return null

  const templatesVM = rootStore.pulseCampaignsStore.viewModels.editVM.templatesVM
  if (!templatesVM) return

  const handleCurrentSurvey = (event: React.MouseEvent<HTMLElement>, val: number | null) => {
    if (val !== null) {
      templatesVM.setCurrentSurveyIndex(val)
    }
  }

  const renderSurveyButtons = () => {
    if (templatesVM.surveys.length === 0) return
    return templatesVM.surveys.map((survey, index) => {
      return (
        <ToggleButton
          value={index}
          key={`survey-${survey.id}-${index}`}
          aria-label={`survey-${survey.id}-btn-${index}`}
          className={
            survey.allValid
              ? 'survey-selector-root-grid-btn'
              : 'survey-selector-root-grid-btn selector-error'
          }
        >
          <Grid item xs className='survey-selector-root-grid-item'>
            <span key={`survey-${survey.id}`} className='survey-selector-survey-btn'>
              {index + 1}
            </span>
          </Grid>
        </ToggleButton>
      )
    })
  }

  const renderSurveyButtonGroup = () => {
    return (
      <div className='survey-selector-root'>
        <Grid container spacing={1} className='survey-selector-root-grid'>
          <ToggleButtonGroup
            size='small'
            value={templatesVM.currentSurveyIndex}
            exclusive
            onChange={handleCurrentSurvey}
            aria-label='survey-selector'
            className='survey-selector-root-grid-btn-grp'
          >
            {renderSurveyButtons()}
          </ToggleButtonGroup>
        </Grid>
      </div>
    )
  }

  const renderCurrentSurveyQuestionCounts = () => {
    if (!templatesVM.currentSurvey) return
    const questionLabel =
      templatesVM.currentSurvey.questions.length > 1 ? lz.questions : lz.question
    return (
      <Typography component='span' className='questions-recap-text'>
        {`${labelsStore.getLabelByLanguageAndFor('pulse')} ${templatesVM.currentSurveyIndex + 1} ${
          lz.includes
        } ${templatesVM.currentSurvey.questions.length} ${questionLabel}`}
      </Typography>
    )
  }

  const renderCurrentSurveyToolbar = () => {
    if (!templatesVM.currentSurvey) return
    return (
      <div className={'survey-card-header-actions'}>
        <Grid item className={'pulse-campaign-previewer-grid-categories'}>
          <Grid
            container
            direction='row'
            justifyContent='space-around'
            alignItems='flex-start'
            className={'pulse-campaign-previewer-grid-categories-grps'}
          >
            <TopToolbar
              vm={manageVM}
              countLabelType={'survey'}
              isTemplate={true}
              questions={vm.currentQuestions}
              templateId={vm.vm.selectedTemplate.objectId}
            />
          </Grid>
        </Grid>
      </div>
    )
  }

  const renderSurveyTemplateInfo = () => {
    if (!templatesVM.currentSurvey) return
    if (!templatesVM.currentSurvey.selectedSurveyTemplate) return
    const currentSurvey = templatesVM.currentSurvey
    if (templatesVM.currentSurvey.selectedSurveyTemplate.questionsCount === 0)
      return renderNoQuestions()
    return (
      <Grid item className='selected-template-screen'>
        <Typography variant='h6' className='template-title'>
          {currentSurvey.selectedSurveyTemplateName}
        </Typography>
        <Typography className='template-body-item'>
          {currentSurvey.selectedSurveyTemplateQuestionsCount}
        </Typography>
        <Typography className='template-body-item'>{`Created ${currentSurvey.selectedSurveyTemplateCreatedAt} by ${currentSurvey.selectedSurveyTemplateCreatedByName}`}</Typography>
        {!currentSurvey.areCreatedAtAndUpdatedAtTheSame && (
          <Typography className='template-body-item'>{`Updated ${currentSurvey.selectedSurveyTemplateUpdatedAt} by ${currentSurvey.selectedSurveyTemplateUpdatedByName}`}</Typography>
        )}
        <Typography className='template-body-item'>{`Owned by ${currentSurvey.selectedSurveyTemplateOwnerName}`}</Typography>
        <Grid item className='template-selected-actions'>
          {templatesVM.isSystemAdmin && (
            <Grid>
              <Button
                variant='outlined'
                onClick={() => currentSurvey.navigateToSelectedSurveyTemplateEditPage()}
              >
                Edit Template
              </Button>
            </Grid>
          )}
          <Grid className='preview-button'>
            <Button variant='outlined' onClick={() => templatesVM.previewSurvey()}>
              Preview Template
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderCurrentSurveyQuestions = () => {
    if (!templatesVM.currentSurvey) return
    if (templatesVM.currentSurvey.questions.length === 0) return renderNoQuestions()
    return (
      <div id='PreviewQuestionsGrid'>
        <Grid
          container
          direction='column'
          justifyContent='flex-start'
          alignItems='stretch'
          className={'pulse-campaign-previewer-grid-questions-list'}
          spacing={2}
          key={`preview-template-grid-${templatesVM.template.objectId}`}
        >
          {vm.currentQuestions.map((row, index) => {
            return (
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                key={`preview-template-item-${index}-${row.objectId}-${row.fk_grandCategoryId.objectId}`}
                className={'survey-selector-survey-card-question'}
              >
                {renderCard(row, index)}
              </Grid>
            )
          })}
        </Grid>
      </div>
    )
  }

  const renderExpandIcon = (vm: PulseQuestionPreviewVM) => {
    let icon = <ChevronRightIcon className={'expand-icon'} />
    if (vm.isExpanded) icon = <ChevronDownIcon className={'expand-icon'} />
    return (
      <IconButton
        disableRipple
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          vm.toggleIsExpanded(!vm.isExpanded)
        }}
        className={'icon-container'}
      >
        {icon}
      </IconButton>
    )
  }

  const renderOptions = (previewVM: PulseQuestionPreviewVM) => {
    if (!previewVM.isExpanded) return
    return <PulseCampaignSurveyQuestionPreviewCard vm={previewVM} />
  }

  const renderCard = (question: PulseQuestionPreviewVM, index: number) => {
    return (
      <Grid
        item
        xs
        id={`pc-summary-q-${question.objectId}`}
        key={`pc-summary-question-grp-${index}`}
        className={'pulse-campaign-previewer-grid-questions-list-root'}
      >
        <div
          className={`pulse-campaign-previewer-grid-questions-list-grp border-cat-${manageVM.getGrandParentCategoryIndex(
            question.fk_grandCategoryId.objectId
          )}`}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            question.toggleIsExpanded(!question.isExpanded)
          }}
        >
          <Grid
            container
            direction='column'
            justifyContent='flex-start'
            alignItems='flex-start'
            className={`pulse-campaign-previewer-grid-questions-list-grp-item border-cat-${manageVM.getGrandParentCategoryIndex(
              question.fk_grandCategoryId.objectId
            )}`}
            key={`pc-summary-question-item-${index}-${question.objectId}`}
          >
            <Typography
              component='span'
              variant='caption'
              className='pulse-campaign-previewer-grid-questions-list-grp-item-text'
            >
              {question.title}
            </Typography>
            <Chip
              variant='outlined'
              color='primary'
              label={question.fk_grandCategoryId.name}
              size='small'
              className='pulse-campaign-previewer-grid-questions-list-grp-item-chip'
            />
          </Grid>
          <div className={'pc-card-header-actions'}>{renderExpandIcon(question)}</div>
        </div>
        {renderOptions(question)}
      </Grid>
    )
  }

  const renderNoQuestions = () => {
    const error = !templatesVM.currentSurvey.questions.length
    const heading = error ? 'no-questions-heading quest-error' : 'no-questions-heading'
    const subHeading = error ? 'no-questions-subheading quest-error' : 'no-questions-subheading'
    return (
      <div className={'no-questions-container'}>
        <span className={heading}>{lz.no_questions_yet}</span>
        <span className={subHeading}>{lz.select_questions_to}</span>
      </div>
    )
  }

  const renderCurrentSurvey = () => {
    if (!templatesVM.currentSurvey) return
    return (
      <div id='PulseCampaignCurrentSurveyPreview'>
        <Grid item className={'survey-selector-survey-card'}>
          {!templatesVM.currentSurvey.isSurveyTemplate && (
            <Grid item className={'survey-selector-survey-card-header'}>
              {renderCurrentSurveyQuestionCounts()}
              {renderCurrentSurveyToolbar()}
            </Grid>
          )}
          <Grid item className={'survey-selector-survey-card-questions'}>
            {!templatesVM.currentSurvey.isSurveyTemplate && renderCurrentSurveyQuestions()}
            {templatesVM.currentSurvey.isSurveyTemplate && renderSurveyTemplateInfo()}
          </Grid>
        </Grid>
      </div>
    )
  }

  const renderSpinner = () => {
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderTemplate = () => {
    if (!templatesVM.isLoaded) return renderSpinner()
    if (templatesVM.templates.length < 1) return
    return (
      <Grid item xs={12} className='survey-selector-grid-bottom-item'>
        <div className='survey-selector-grid-bottom-container'>{renderCurrentSurvey()}</div>
      </Grid>
    )
  }

  const renderPreviewer = () => {
    if (!templatesVM.isLoaded) return renderSpinner()
    if (templatesVM.templates.length < 1) return renderEmptyState()
    return (
      <Grid item xs={12} className='survey-selector-grid-top-item'>
        <div id='PulseCampaignTemplatePreviewer'>
          <Typography component='span' className='survey-selector-title'>
            {`${lz.no_of} ${labelsStore.getLabelByLanguageAndFor('pulses')}`}
          </Typography>
          {renderSurveyButtonGroup()}
        </div>
      </Grid>
    )
  }

  const renderEmptyState = () => {
    if (templatesVM.templates.length > 0) return null
    return (
      <div className={'previewer-empty-state'}>
        <EmptyState
          image={emptyResults}
          title={`${lz.no_templates}`}
          subtitle1={`${lz.create_a_company} ${lz.template} ${lz.now}.`}
          subtitle2={''}
          action={
            <Button
              onClick={() => templatesVM.createPulseTemplate()}
              className={'empty-state-action-btn'}
              variant={'contained'}
            >
              {lz.create_template}
            </Button>
          }
        />
      </div>
    )
  }

  return (
    <>
      <div id='PulseCampaignTemplatePreviewerGrid'>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='flex-start'
          className='survey-selector-grid-top-container'
        >
          {renderPreviewer()}
        </Grid>
        {renderTemplate()}
      </div>
    </>
  )
}

export default inject(
  'pulseCampaignsStore',
  'localizationStore',
  'labelsStore'
)(observer(PulseCampaignTemplatePreviewer))
