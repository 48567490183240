import { action, computed, observable, when } from 'mobx'
import { PulseSetsFilterVM } from '../../pulse-sets/view-models/PulseSetsFilterVM'
import { RootStore } from '../../stores/RootStore'
import PulseCategory from '../aggregate/PulseCategory'
import { IPulseCategoriesFindRequest } from '../interfaces/IPulseCategoriesFindRequest'
import { GrandCategoryTabVM } from './GrandCategoryTabVM'
import { GrandParentCategoryRowVM } from './GrandParentCategoryRowVM'
import { PulseCategoriesDataStore } from './PulseCategoriesDataStore'
import { PulseCategoryEditVM } from './PulseCategoryEditVM'

export class PulseCategoriesManageVM {
  private rootStore: RootStore
  public dataStore: PulseCategoriesDataStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.pulseSetsFilterVM = new PulseSetsFilterVM(this.rootStore, this)
    this.dataStore = new PulseCategoriesDataStore(this.rootStore, {} as IPulseCategoriesFindRequest)
    this.loadDataStore()
  }

  @observable public pulseSetsFilterVM: PulseSetsFilterVM = null
  @observable public categoryFilterAnchorEl: any = null
  @observable public categoryVMs: GrandParentCategoryRowVM[] = []
  @observable public selectedTabs: number[] = []

  @action
  public loadDataStore() {
    this.dataStore = new PulseCategoriesDataStore(this.rootStore, {} as IPulseCategoriesFindRequest)
    this.dataStore.loadListRecords()
    when(
      () => this.dataStore.isLoaded,
      () => {
        this.dataStore.rows.forEach((p, i) => this.toggleTabIndex(i + 1))
      }
    )
  }

  @computed
  public get isLoaded(): boolean {
    return this.dataStore.isLoaded
  }

  @computed
  public get checkedPulseSetIds(): string[] {
    return this.pulseSetsFilterVM.checkedPulseSetIds
  }

  @computed
  public get grandCategoryTabs(): GrandCategoryTabVM[] {
    return this.dataStore.rows
      .filter((e) => !e.grandCategoryId)
      .map((c, i) => new GrandCategoryTabVM(c, i + 1, this.selectedTabs))
  }

  @action
  public createNewPulseCategory() {
    this.rootStore.pulseCategoriesStore.viewModels.editVM = new PulseCategoryEditVM(
      this.rootStore,
      PulseCategory.create(undefined, undefined)
    )
  }

  @action
  public toggleTabIndex(index: number) {
    if (this.selectedTabs.includes(index)) {
      const indexOf = this.selectedTabs.indexOf(index)
      return this.selectedTabs.splice(indexOf, 1)
    }
    return this.selectedTabs.push(index)
  }

  @action
  public selectAllTabs() {
    this.selectedTabs = this.grandCategoryTabs.map((t) => t.index)
  }

  @computed
  public get numberOfGrandCategoryTabs(): number {
    return this.grandCategoryTabs.length
  }

  @computed
  public get numberOfCategoriesSelected(): number {
    return this.selectedGrandCategoryTabs.length
  }

  @computed
  public get numberOfCategoriesSelectedText(): string {
    return `Showing ${this.numberOfCategoriesSelected} of ${this.numberOfGrandCategoryTabs} Category Sets`
  }

  @computed
  public get shouldShowAllCategoriesButtonBeDisabled(): boolean {
    return this.selectedGrandCategoryTabs.length === this.numberOfGrandCategoryTabs
  }

  @computed
  public get categoryFilterBtnLabel(): string {
    if (this.selectedGrandCategoryTabs.length === this.numberOfGrandCategoryTabs)
      return 'Showing All Category Sets'
    return this.numberOfCategoriesSelectedText
  }

  @computed
  public get selectedGrandCategoryTabs(): string[] {
    return this.grandCategoryTabs.filter((gTab) => gTab.isSelected).map((gTab) => gTab.objectId)
  }

  public getGrandParentCategory(objectId: string): GrandParentCategoryRowVM {
    return this.categoryVMs.find((cat) => cat.objectId === objectId)
  }

  public getGrandParentCategoryIndex(objectId: string): number {
    const found = this.getGrandParentCategory(objectId)
    if (!found) return undefined
    return found.index
  }

  @action
  public addCategoryVM(categoryVM: GrandParentCategoryRowVM) {
    this.categoryVMs.push(categoryVM)
    this.categoryVMs.slice()
  }

  public grandParentCategoryRows(): GrandParentCategoryRowVM[] {
    const selectedIds = []
    const allIds = []
    this.grandCategoryTabs.forEach((t) => {
      if (t.isSelected) {
        selectedIds.push(t.objectId)
      }
      allIds.push(t.objectId)
    })
    const categories = this.dataStore.rows.filter((e) => {
      if (selectedIds.includes(e.objectId)) return true
      return false
    })
    return categories.map((c) => {
      let foundCatVM = this.categoryVMs.find((cVM) => c.objectId === cVM.objectId)
      let index = allIds.findIndex((id) => c.objectId === id)

      if (!foundCatVM) {
        foundCatVM = new GrandParentCategoryRowVM(this.rootStore, c, this)
        foundCatVM.setIndex(index + 1)
        this.addCategoryVM(foundCatVM)
      }
      return foundCatVM
    })
  }

  @computed
  public get categoryFilterMenuShown(): boolean {
    return this.categoryFilterAnchorEl !== null
  }

  @action
  public openCategoryFilterMenu(e: any) {
    this.categoryFilterAnchorEl = e
  }

  @action
  public closeCategoryFilterMenu() {
    this.categoryFilterAnchorEl = null
  }
}
